import React, { useEffect } from "react";
import { Button, CollapseData, CollapseHeader, Wrapper } from "./index.styled";

import { Collapse } from "antd";
import { useState } from "react";
import moment from "moment";
import CustomTable from "../../components/customTable";
import { useDispatch, useSelector } from "react-redux";
import { getAdmins } from "../../redux/action/appActions";
import CustomPagination from "../../components/customPagination/CustomPagination";
import { TableAction } from "../../components/customTable/index.styled";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constant";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import AddAdmin from "../../components/AddAdmin/AddAdmin";
import EditAdmin from "../../components/EditAdmin/EditAdmin";

const Admin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const admins = useSelector((state) => state.app.admins);
  const currentUser = useSelector((state) => state.auth.user);

  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selected, setSelected] = useState();

  const [data, setData] = useState([
    {
      name: "Abdullah",
      role: "Admin",
      status: "Active",
      joinDate: "05/28/2024",
      lastLogin: moment().format("YYYY/MM/DD HH:mm:ss"),
    },
  ]);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Join Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "",
      render: (date, record) => (
        <TableAction>
          <span
            style={{
              zIndex: currentUser?.role !== "admin" ? "1" : "-1",
              position: "relative",
            }}
            onClick={() => {
              setSelected(record);
              setShowDeleteModal(true);
            }}
          >
            <img src="/icons/delete.svg" alt="" />
          </span>
          <span
            style={{
              zIndex: currentUser?.role !== "admin" ? "1" : "-1",
              position: "relative",
            }}
            onClick={() => {
              setSelected(record);
              setShowEditModal(true);
            }}
          >
            <img src="/icons/edit.svg" alt="" />
          </span>
        </TableAction>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: (
        <CollapseHeader>
          <h1>
            Admin List <span>{`(${admins?.total || 0})`}</span>
          </h1>
          <span>view all</span>
        </CollapseHeader>
      ),
      children: (
        <>
          <CustomTable data={data} columns={columns} loading={loading} />
          <CustomPagination
            total={admins.total || 0}
            handleLoadData={(page, count) => {
              setLoading(true);
              dispatch(getAdmins(setLoading, page, count));
            }}
          />
        </>
      ),
    },
  ];

  const handleCancel = () => {
    setShowDeleteModal(false);
    setShowAddModal(false);
    setShowEditModal(false);
  };

  const fetchData = () => {
    setLoading(true);
    dispatch(getAdmins(setLoading, 1, 10));
  };

  useEffect(() => {
    if (currentUser) {
      fetchData();
    }
  }, [currentUser]);

  useEffect(() => {
    if (admins) {
      setData(admins.admins);
    }
  }, [admins]);

  return (
    <Wrapper>
      {currentUser?.role !== "admin" && (
        <Button onClick={() => setShowAddModal(true)}>Add Admin</Button>
      )}
      <div className="tabContainer">
        <CollapseData>
          <Collapse items={items} defaultActiveKey={1} />
        </CollapseData>
      </div>
      <DeleteModal
        visible={showDeleteModal}
        setVisible={handleCancel}
        message="Admin"
        record={selected?.id}
        fetchData={fetchData}
      />

      <AddAdmin
        visible={showAddModal}
        setVisible={handleCancel}
        titleName="Admin"
        fetchData={fetchData}
      />

      <EditAdmin
        visible={showEditModal}
        setVisible={handleCancel}
        fetchData={fetchData}
        data={selected}
      />
    </Wrapper>
  );
};

export default Admin;
