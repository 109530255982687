import { Form, Input } from "antd";
import React from "react";
import styled from "styled-components";

const PasswordInput = ({ label, name, placeholder, rules }) => {
  return (
    <InputContainer>
      <Form.Item label={label} name={name} rules={rules}>
        <Input.Password placeholder={placeholder} />
      </Form.Item>
    </InputContainer>
  );
};

export default PasswordInput;

const InputContainer = styled("div")`
  .ant-form-item-required {
    color: #ffffff;
    ::before {
      display: none !important;
    }
  }
  label {
    color: #ffffff !important ;
  }
  .ant-form-item-control-input {
    background: transparent;
    width: 100%;
    .ant-input-affix-wrapper {
      border-radius: 40px;
      border: none;
      color: #ffffff;
      border: 1px solid #ffffff;
      height: 54px;
      background: #15112c !important;
      .ant-input-suffix,
      input {
        color: #ffffff;
        background: #15112c !important;
        &::placeholder {
          color: #ffffff99;
        }
      }
      .ant-input-suffix {
        svg {
          color: #436ddd;
        }
      }
    }
    .ant-form-item-control-input-content {
    }
  }
`;
