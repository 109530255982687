import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "./redux/action/authActions";
import { useNavigate } from "react-router-dom";
import AllRoutes from "./routes";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate("/login"); // Redirect to login if not authenticated
    }
  }, [loading, isAuthenticated, navigate]);

  return (
    <div className="App">
      <AllRoutes />
    </div>
  );
}

export default App;
