import moment from "moment";
import React, { useEffect, useState } from "react";
import CustomTable from "../../components/customTable";
import { CollapseData, CollapseHeader, Title, Wrapper } from "./index.styled";
import { Collapse } from "antd";
import CustomPagination from "../../components/customPagination/CustomPagination";
import { useDispatch, useSelector } from "react-redux";
import { getUserPlayHistory } from "../../redux/action/appActions";
import { useLocation } from "react-router-dom";

const UserActivity = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const singleUser = useSelector((state) => state.app.singleUser);
  const users = useSelector((state) => state.app.users);
  const { user } = useSelector((state) => state.auth);
  const [data, setData] = useState([
    {
      user: "Abdullah",
      subscription: "Subscribe",
      status: "Active",
      updatedAt: moment().format("YYYY/MM/DD HH:mm:ss"),
      skipDuration: moment().format("YYYY/MM/DD HH:mm:ss"),
      type: "Pro",
      contentTitle: "Latest News",
      playedFrom: moment().format("YYYY/MM/DD HH:mm:ss"),
    },
  ]);

  const columns = [
    // {
    //   title: "User",
    //   dataIndex: "user",
    //   key: "user",
    //   render: (id) => users.users.find((item) => item.id === id)?.fullname,
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (obj) => obj.status,
    },
    {
      title: "Updated At",
      dataIndex: "status",
      key: "status",
      render: (obj) => moment(obj.date).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      title: "Skip Duration",
      dataIndex: "skipDuration",
      key: "skipDuration",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Content Title",
      dataIndex: "contentTitle",
      key: "contentTitle",
      render: (text, record) =>
        record.type === "podcast" ? (
          <Title>{record.podcast?.podcast?.title || "-"}</Title>
        ) : record.type === "news" ? (
          <Title>{record.news?.title || "-"}</Title>
        ) : (
          "-"
        ),
    },
    {
      title: "Play Option",
      dataIndex: "playOption",
      key: "playOption",
    },
    {
      title: "Played From",
      dataIndex: "playedFrom",
      key: "playedFrom",
    },
  ];

  const items = [
    {
      key: "1",
      label: (
        <CollapseHeader>
          <h1>Play Back History</h1> <span>view all</span>
        </CollapseHeader>
      ),
      children: (
        <>
          <CustomTable data={data} columns={columns} loading={loading} />
          <CustomPagination
            total={singleUser?.total || 0}
            handleLoadData={(page, count) => {
              setLoading(true);
              let params = {
                page: page,
                count: count,
              };

              if (location.search) {
                const queryParams = new URLSearchParams(location.search);
                if (queryParams.has("userId")) {
                  dispatch(
                    getUserPlayHistory(
                      params,
                      queryParams.get("userId"),
                      setLoading
                    )
                  );
                  // params["userId"] = queryParams.get("userId");
                }
              }
            }}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    let params = {
      page: 1,
      count: 10,
    };

    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has("userId") && user) {
        dispatch(
          getUserPlayHistory(params, queryParams.get("userId"), setLoading)
        );
        // params["userId"] = queryParams.get("userId");
      }
    }
  }, [location, user]);

  // useEffect(() => {
  //   if (location.state) {
  //     setLoading(true);
  //     dispatch(getUserPlayHistory(location.state, setLoading, 1, 10));
  //   }
  // }, [location]);

  useEffect(() => {
    if (singleUser) {
      setData(singleUser.playbackHistory);
    }
  }, [singleUser]);

  return (
    <Wrapper>
      <div className="tabContainer">
        <CollapseData>
          <Collapse items={items} defaultActiveKey={1} />
        </CollapseData>
      </div>
    </Wrapper>
  );
};

export default UserActivity;
