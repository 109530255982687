import React, { useEffect, useState } from "react";
import {
  Collapse,
  Input,
  Button,
  Modal,
  Form,
  DatePicker,
  Checkbox,
  Tooltip,
  message,
} from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import CustomTable from "../../components/customTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getPromotions,
  createPromotion,
  updatePromotion,
} from "../../redux/action/appActions";
import {
  CollapseData,
  CollapseHeader,
  Wrapper,
  ButtonContainer,
} from "./index.styled";
import moment from "moment";

const PromotionsPage = () => {
  const dispatch = useDispatch();
  const promotions = useSelector((state) => state.app.promotions);
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [sortOrder, setSortOrder] = useState("descend");

  const [modalVisible, setModalVisible] = useState(false);
  const [editingPromotion, setEditingPromotion] = useState(null);

  const [form] = Form.useForm();

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (text) => text !== "" && text !== "undefined" && text,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Duration (Days)",
      dataIndex: "duration",
      key: "duration",
      render: (value) =>
        typeof value === "number" && value !== null && value !== undefined
          ? value
          : "",
    },
    {
      title: () => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "5px",
            }}
          >
            <Tooltip title="Sort Ascending">
              <span
                style={{ cursor: "pointer", fontSize: "6.8px" }}
                onClick={() => handleSort("ascend")} // Up arrow for ascending
              >
                ▲
              </span>
            </Tooltip>
            <Tooltip title="Sort Descending">
              <span
                style={{ cursor: "pointer", fontSize: "6.8px" }}
                onClick={() => handleSort("descend")} // Down arrow for descending
              >
                ▼
              </span>
            </Tooltip>
          </div>
          Creation Date
        </div>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => text !== "" && text !== "undefined" && text,
      width: 150,
    },
    {
      title: "Reusable",
      dataIndex: "reuseable",
      key: "reuseable",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Count",
      dataIndex: "useHistory",
      key: "count",
      render: (useHistory) => useHistory?.length || 0, // Show the count of usage
    },
    {
      title: "Limit",
      dataIndex: "maxUses",
      key: "maxUses",
      render: (text) => text,
    },
    {
      title: "Actions",
      key: "action",
      render: (text, record) => (
        <Tooltip title="Edit">
          <Button
            type="text"
            style={{ color: "black", border: "none" }}
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          />
        </Tooltip>
      ),
    },
  ];

  const handleSort = (order) => {
    setSortOrder({
      field: "createdAt",
      order: order, // "ascend" or "descend"
    });

    // Sort the data manually based on the selected order
    setData(
      [...data].sort((a, b) =>
        order === "ascend"
          ? moment(a.createdAt).unix() - moment(b.createdAt).unix()
          : moment(b.createdAt).unix() - moment(a.createdAt).unix()
      )
    );
  };

  const items = [
    {
      key: "1",
      label: (
        <CollapseHeader>
          <h1>
            Promotions <span>{`(${promotions?.length || 0})`}</span>
          </h1>
          <span>view all</span>
        </CollapseHeader>
      ),
      children: (
        <CustomTable
          data={data}
          columns={columns}
          loading={loading}
          disablePagination={true}
        />
      ),
    },
  ];

  const fetchData = (page, size) => {
    setLoading(true);
    dispatch(getPromotions(setLoading, page, size));
    setData((prevData) => {
      return [...prevData].sort(
        (a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix()
      );
    });
    setModalVisible(false);
    form.resetFields();
  };

  const handleEdit = (promotion) => {
    setEditingPromotion(promotion);
    setModalVisible(true);
    form.setFieldsValue({
      code: promotion.code,
      startDate: moment(promotion.startDate),
      endDate: moment(promotion.endDate),
      duration: promotion.duration,
      description: promotion.description,
      maxUses: promotion.maxUses,
      reuseable: promotion.reuseable,
    });
  };

  const handleCreate = (values) => {
    const promotionData = {
      code: values.code,
      startDate: values.startDate.toISOString(),
      endDate: values.endDate.toISOString(),
      duration: values.duration,
      description: values.description,
      maxUses: values.maxUses,
      reuseable: values.reuseable || false,
    };

    setLoading(true);
    dispatch(createPromotion(setLoading, promotionData))
      .then(() => {
        fetchData(); // Fetch updated data
        setModalVisible(false); // Close the modal after successful creation
        form.resetFields(); // Reset form fields
      })
      .catch((err) => {
        setModalVisible(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdate = (values) => {
    const promotionData = {
      startDate: values.startDate.toISOString(),
      endDate: values.endDate.toISOString(),
      duration: values.duration,
      description: values.description,
      maxUses: values.maxUses,
      reuseable: values.reuseable || false,
    };

    setLoading(true);
    dispatch(updatePromotion(setLoading, editingPromotion._id, promotionData));
    setModalVisible(false);
    form.resetFields();
  };

  const validateDates = () => {
    const values = form.getFieldsValue();
    var startDateVar = values.startDate;
    var dateObjStart = new Date(startDateVar);
    var startDate = moment(dateObjStart);
    var endDateVar = values.endDate;
    var dateObjEnd = new Date(endDateVar);
    var endDate = moment(dateObjEnd);

    // Ensure current date is also a Moment object
    const currentDate = moment().startOf("day");

    if (startDate && endDate) {
      // if (startDate.isBefore(currentDate, "day")) {
      //   message.error("Start date cannot be before the current date!");
      //   return false;
      // }
      if (endDate.isBefore(currentDate, "day")) {
        message.error("End date cannot be before the current date!");
        return false;
      }
      if (endDate.isSameOrBefore(startDate, "day")) {
        message.error(
          "End date cannot be the same as or before the start date!"
        );
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (user) {
      fetchData(1, 10);
    }
  }, [user]);

  useEffect(() => {
    if (promotions) {
      setData(
        [...promotions].sort(
          (a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix()
        )
      );
    }
  }, [promotions]);

  return (
    <>
      <ButtonContainer>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setEditingPromotion(null);
            setModalVisible(true);
          }}
        >
          Add Promotion Code
        </Button>
      </ButtonContainer>

      <Wrapper>
        <div className="tabContainer">
          <CollapseData>
            <Collapse items={items} defaultActiveKey={["1"]} />
          </CollapseData>
        </div>
      </Wrapper>

      <Modal
        title={editingPromotion ? "Edit Promotion" : "Add Promotion"}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          form.resetFields();
        }}
        okText={editingPromotion ? "Update" : "Create"}
        onOk={() => {
          if (validateDates()) {
            form.submit();
          }
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={editingPromotion ? handleUpdate : handleCreate}
        >
          {!editingPromotion && (
            <Form.Item
              name="code"
              label="Code"
              rules={[
                { required: true, message: "Please input the promotion code!" },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            name="startDate"
            label="Start Date"
            rules={[
              { required: true, message: "Please select the start date!" },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="endDate"
            label="End Date"
            rules={[{ required: true, message: "Please select the end date!" }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="duration"
            label="Duration (Days)"
            rules={[{ required: true, message: "Please input the duration!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Please enter a description!" }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="maxUses"
            label="Max Uses"
            rules={[
              {
                required: true,
                message: "Please enter the maximum number of uses!",
              },
            ]}
          >
            <Input type="number" style={{ width: "75px" }} min={1} />
          </Form.Item>
          <Form.Item name="reuseable" valuePropName="checked">
            <Checkbox>Reusable</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PromotionsPage;
