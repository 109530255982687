import React, { useEffect, useState } from "react";
import { Card, Wrapper } from "./index.styled";
import { LineChart } from "../../components/lineChart";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../redux/action/appActions";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const dashboardData = useSelector((state) => state.app.dashboard);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(getDashboardData(setLoading));
    }
  }, [user]);
  return (
    <Wrapper>
      <Row gutter={[30, 30]}>
        <Col span={12}>
          <Card>
            <h1>Users</h1>
            <h1>{dashboardData?.users || 0}</h1>
            {/* <div style={{ height: "300px", width: "100%" }}>
              <LineChart />
            </div> */}
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <h1>News</h1>
            <h1>{dashboardData?.news || 0}</h1>
            {/* <div style={{ height: "300px", width: "100%" }}>
              <LineChart />
            </div> */}
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <h1>Podcasts</h1>
            <h1>{dashboardData?.podcasts || 0}</h1>
            {/* <div style={{ height: "300px", width: "100%" }}>
              <LineChart />
            </div> */}
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <h1>Custom Channels</h1>
            <h1>{dashboardData?.customChannels || 0}</h1>
            {/* <div style={{ height: "300px", width: "100%" }}>
              <LineChart />
            </div> */}
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <h1>Admins</h1>
            <h1>{dashboardData?.admins || 0}</h1>
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <h1>AudioAlerts</h1>
            <h1>{dashboardData?.audioAlerts || 0}</h1>
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <h1>Categories</h1>
            <h1>{dashboardData?.categories || 0}</h1>
          </Card>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Dashboard;
