import { Form, Input } from "antd";
import React from "react";
import styled from "styled-components";

const CustomInput = ({ label, name, placeholder }) => {
  return (
    <InputContainer>
      <Form.Item
        label={label}
        name={name}
        rules={[
          {
            required: true,
            message: `Please input your ${label}!`,
          },
        ]}
      >
        <Input placeholder={placeholder} />
      </Form.Item>
    </InputContainer>
  );
};

export default CustomInput;

const InputContainer = styled("div")`
  .ant-form-item-required {
    color: #ffffff;
    ::before {
      display: none !important;
    }
  }
  label {
    color: #ffffff !important ;
  }
  .ant-input {
    color: #ffffff;
    border: 1px solid #ffffff;
    background: #15112c;
    width: 100%;
    height: 54px;
    border-radius: 40px;
    &::placeholder {
      color: #ffffff99;
    }
  }
  @media (max-width: 768px) {
    & {
      label {
        color: #ffffff !important ;
        font-size: 0.9em !important;
      }
    }
  }
`;
