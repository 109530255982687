import React, { useState } from "react";
import { Buttons, MessageContainer } from "./index.styled";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { deleteAdmins } from "../../redux/action/appActions";

const DeleteModal = ({ visible, setVisible, message, record, fetchData }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const callback = () => {
    setLoading(false);
    setVisible(false);
    fetchData();
  };

  const handleDelete = () => {
    console.log(record);
    setLoading(true);
    dispatch(deleteAdmins(callback, record));
  };
  return (
    <MessageContainer
      centered
      footer={false}
      open={visible}
      onCancel={setVisible}
    >
      <h3>Sure you want to Delete?</h3>
      <p>Are you sure you want to Delete this {message}?</p>
      <Buttons>
        <Button onClick={setVisible}>No, cancel</Button>
        <Button loading={loading} onClick={handleDelete}>
          Yes, Confirm
        </Button>
      </Buttons>
    </MessageContainer>
  );
};

export default DeleteModal;
