import {
  SET_USERS,
  SET_SINGLE_USER,
  SET_ADMINS,
  SET_DASHBOARD,
  SET_CUSTOMCHANEEL,
  SET_CATEGORIES,
  SET_PROMOTIONS,
  ADD_PROMOTION,
  UPDATE_PROMOTION,
  SEND_NOTIFICATIONS,
  SEARCH_USERS,
  SET_NOTIFICATIONS,
  SET_NOTIFICATION_TEMPLATES,
  ADD_NOTIFICATION_TEMPLATE,
  UPDATE_NOTIFICATION_TEMPLATE,
  DELETE_NOTIFICATION_TEMPLATE,
} from "../Constants";

const initState = {
  users: "",
  singleUser: "",
  admins: "",
  dashboard: "",
  customchannels: "",
  promotions: "",
  notifications: "",
  notificationTemplates: [],
};

const appReducer = function (state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_USERS:
      return {
        ...state,
        users: payload,
      };
    case SET_SINGLE_USER:
      return {
        ...state,
        singleUser: payload,
      };
    case SET_ADMINS:
      return {
        ...state,
        admins: payload,
      };
    case SET_DASHBOARD:
      return {
        ...state,
        dashboard: payload,
      };
    case SET_CUSTOMCHANEEL:
      return {
        ...state,
        customchannels: payload,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };
    case SET_PROMOTIONS:
      return {
        ...state,
        promotions: payload,
      };
    case ADD_PROMOTION:
      return {
        ...state,
        promotions: [...state.promotions, payload],
      };
    case UPDATE_PROMOTION:
      return {
        ...state,
        promotions: state.promotions.map((promo) =>
          promo._id === payload._id ? payload : promo
        ),
      };
    case SEND_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload,
      };
    case SEARCH_USERS:
      return {
        ...state,
        users: payload,
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload.notifications,
        totalNotifications: payload.total,
      };
    case SET_NOTIFICATION_TEMPLATES:
      return {
        ...state,
        notificationTemplates: payload.notificationTemplates || [],
        totalNotificationTemplates: payload.total || 0,
      };
    case ADD_NOTIFICATION_TEMPLATE:
      return {
        ...state,
        notificationTemplates: [...state.notificationTemplates, payload],
      };
    case UPDATE_NOTIFICATION_TEMPLATE:
      return {
        ...state,
        notificationTemplates: state.notificationTemplates.map((template) =>
          template._id === payload._id ? payload : template
        ),
      };
    case DELETE_NOTIFICATION_TEMPLATE:
      return {
        ...state,
        notificationTemplates: state.notificationTemplates.filter(
          (template) => template._id !== payload
        ),
      };
    default:
      return { ...state };
  }
};

export default appReducer;
