import { Table } from "antd";
import styled from "styled-components";

export const StyledTable = styled(Table)`
  border-radius: 0px 0px 0px 0px !important;
  margin-top: -6px;
  .ant-table-pagination {
    display: none;
  }

  .ant-table-column-sorters {
    flex-direction: row-reverse;
    justify-content: start;
    display: inline-flex !important;
    .ant-table-column-title {
      margin-left: 0.5em;
    }
  }
  .ant-table-column-sorter {
    margin-right: 0.5em;
  }

  .ant-table-column-sorter-inner {
    .anticon-caret-down,
    .anticon-caret-up {
      svg {
        color: #ffffff99 !important;
      }
    }
    .active {
      svg {
        color: #ffffff !important;
      }
    }
  }
  .ant-table {
    background: #15112c !important;
    color: #ffffff;
    .ant-table-thead {
      .ant-table-cell {
        background: #24204a !important;
        // text-align: center;
        color: #ffffff;
        border-start-start-radius: 0px !important;
        &::before {
          display: none;
        }
        &:nth-child(1) {
          text-align: left !important;
        }
        &:last-child {
          border-start-end-radius: 0px !important;
        }
      }
      .ant-table-selection-column {
        .ant-table-selection {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-cell,
      .ant-table-cell-row-hover {
        background: #15112c !important;
        // cursor: pointer;
        // text-align: center;
        padding: 0.4em 0.5em;
        h3 {
          margin: 0;
          color: #ffffff;
        }
        span {
          color: #ffffff80;
        }
        .patients {
          display: flex;
          align-items: center;
          cursor: pointer;
          span {
            color: #ffffff;
          }
          padding-left: 1em;
        }
        &:nth-child(1) {
          text-align: left !important;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 1em;
          }
        }
        // .ant-checkbox-wrapper {
        //   h3 {

        //   }
        // }
        @media (max-width: 992px) {
          & {
            h3 {
              margin: 0;
              font-size: 9px;
            }
            span {
              font-size: 9px !important;
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    & {
      .ant-table {
        background: #15112c !important;
        color: #ffffff;
        .ant-table-thead {
          .ant-table-cell {
            background: #15112c !important;
            font-weight: 400 !important;
            font-size: 12px;
          }
        }
        .ant-table-tbody {
          .ant-table-cell {
            font-size: 12px;
          }
        }
      }
    }
  }
`;
export const TableAction = styled("div")`
  padding: 15px 0;
  span {
    margin: 0 0.4em;
    cursor: pointer;
  }
  .permission {
    padding: 2px 8px;
    cursor: unset;
    color: #000000 !important;
    background: #a0c9c9;
    width: 113px;
    height: 29px;
    border-radius: 4px;
  }
  .showMore {
    margin-top: 0.7em !important;
    font-size: 12px;
    color: #ffffff !important;
  }
`;
