import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import styled from "styled-components";

const CustomPagination = ({ handleLoadData, total }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentItems, setCurrentItems] = useState([]);

  //   useEffect(() => {
  //     const start = (currentPage - 1) * pageSize;
  //     const end = start + pageSize;
  //     setCurrentItems(items.slice(start, end));
  //   }, [currentPage, pageSize]);

  const handlePageChange = (page, pageSize) => {
    handleLoadData(page, pageSize);
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <StyledPagination style={{ textAlign: "right" }}>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={total}
        onChange={handlePageChange}
        showSizeChanger={false}
        onShowSizeChange={handlePageChange}
      />
    </StyledPagination>
  );
};

export default CustomPagination;

const StyledPagination = styled("div")`
  padding: 3em 0 0em 0;
  // .ant-pagination .ant-pagination-item a {
  //   color: #fff;
  // }
  .ant-pagination .ant-pagination-item-active a {
    color: #24204a;
  }
  .ant-pagination-item-link-icon {
    color: #fff !important;
  }
  svg {
    color: #fff;
  }
  .ant-pagination-item-ellipsis {
    color: #fff !important;
  }
`;
