import moment from "moment";
import React, { useEffect, useState } from "react";
import CustomTable from "../../components/customTable";
import {
  CollapseData,
  CollapseHeader,
  SearchArea,
  Wrapper,
} from "./index.styled";
import { Collapse, Input } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constant";
import { getCustomChannels } from "../../redux/action/appActions";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../components/customPagination/CustomPagination";

const { Search } = Input;

const CustomChannels = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const customChannels = useSelector((state) => state.app.customchannels);
  const { user } = useSelector((state) => state.auth);
  console.log("items:");
  console.log(customChannels);

  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [data, setData] = useState([]);

  const columns = [
    {
      title: "Name",
      dataIndex: "user",
      key: "user",
      render: (text) => text.fullname || "N/A",
    },
    {
      title: "Email",
      dataIndex: "user",
      key: "user",
      render: (text) => text.email || "N/A",
    },
    {
      title: "Channel Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Categories",
      dataIndex: "categories",
      key: "categories",
      render: (categories) =>
        categories && categories.length > 0 ? (
          <ul>
            {categories.map((category, index) => (
              <li key={index}>{category.title}</li>
            ))}
          </ul>
        ) : (
          "None"
        ),
    },
    {
      title: "Audio Alerts",
      dataIndex: "audioAlerts",
      key: "audioAlerts",
      render: (alerts) =>
        alerts && alerts.length > 0 ? (
          <ul>
            {alerts.map((alert, index) => (
              <li key={index}>{alert.term}</li>
            ))}
          </ul>
        ) : (
          "None"
        ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) =>
        date ? moment(date).format("YYYY/MM/DD HH:mm:ss") : "N/A",
    },
  ];

  const items = [
    {
      key: "1",
      label: (
        <CollapseHeader>
          <h1>
            Custom Channels <span>{`(${customChannels?.total || 0})`}</span>
          </h1>
          <span>view all</span>
        </CollapseHeader>
      ),
      children: (
        <>
          <CustomTable data={data} columns={columns} loading={loading} />
          <CustomPagination
            total={customChannels?.total || 0}
            handleLoadData={(page, count) => {
              let params = {
                page: page,
                count: count,
              };

              if (location.search) {
                const queryParams = new URLSearchParams(location.search);
                if (queryParams.has("userId")) {
                  params["userId"] = queryParams.get("userId");
                }
              }
              fetchData(params, searchKeyword);
            }}
          />
        </>
      ),
    },
  ];

  const fetchData = (params) => {
    setLoading(true);
    dispatch(getCustomChannels(setLoading, params));
  };

  const onSearch = (value) => setSearchKeyword(value);

  useEffect(() => {
    let params = {
      page: 1,
      count: 10,
    };

    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has("userId")) {
        params["userId"] = queryParams.get("userId");
      }
    }
    if (user) {
      fetchData(params);
    }
  }, [location, user]);

  useEffect(() => {
    if (customChannels) {
      console.log("Custom Channels Data:", customChannels);
      setData(customChannels.playbackHistory);
    }
  }, [customChannels]);

  return (
    <>
      <SearchArea>
        <Search placeholder="Search" onSearch={onSearch} />
      </SearchArea>
      <Wrapper>
        <div className="tabContainer">
          <CollapseData>
            <Collapse items={items} defaultActiveKey={1} />
          </CollapseData>
        </div>
      </Wrapper>
    </>
  );
};

export default CustomChannels;
