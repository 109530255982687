import { Input } from "antd";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { ROUTES } from "../../constant";
import { useSelector } from "react-redux";

const { Search } = Input;

const Header = ({ profileAddress, userType }) => {
  const user = useSelector((state) => state.auth.user);
  const [showNotification, setShowNotification] = useState(false);
  const onSearch = (value, _e, info) => console.log(info?.source, value);
  return (
    <HeaderContainer>
      <LogoArea>
        {/* <span onClick={onShow}>
          <img src="/icons/menu-icon.svg" alt="" />
        </span> */}
        <img src="/images/logo.png" alt="" />
      </LogoArea>
      {/* <img src="/icons/search-icon.svg" alt="" /> */}
      {/* <SearchArea>
        <Search placeholder="Search" onSearch={onSearch} />
      </SearchArea> */}
      <NotificationArea>
        {/* <NavLink
          to="#"
          onClick={(e) => {
            setShowNotification(!showNotification);
            e.preventDefault();
          }}
        >
          <span>6</span>
          <img src="/icons/bell-icon.svg" alt="" />
        </NavLink>
        <NavLink to="/user_chat">
          <span>4</span>
          <img src="/icons/message-icon.svg" alt="" />
        </NavLink> */}
        <NavLink to={ROUTES.PROFILE}>
          <UserName>{user && user?.name}</UserName>
          {/* <img src="/images/user.png" alt="" /> */}
        </NavLink>
      </NotificationArea>
    </HeaderContainer>
  );
};

export default Header;

const UserName = styled("h2")`
  color: #fff;
  font-size: 1.3em;
  font-weight: 600;
`;

const HeaderContainer = styled("header")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  background: #15112c;
  height: 70px;
  box-shadow: 0px 0px 10px 10px #000000;
`;
const LogoArea = styled("div")`
  // background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 325px;
  height: 70px;
  span {
    cursor: pointer;
    margin-right: 1em;
  }

  img {
    max-width: 80%;
  }
  @media (max-width: 1600px) {
    & {
      width: 335px;
    }
  }
`;
const SearchArea = styled("div")`
  width: 67%;
  margin: auto;
  .ant-input-search {
    background: #15112c;
  }
  .ant-input-wrapper {
    border-radius: 40px;
    background: #15112c;
    color: #ffffff;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row-reverse !important;
    overflow: hidden;
    height: 50px;
    padding-left: 0.5em;
    input {
      border: none;
      font-size: 1em;
      background: #15112c;
      color: #ffffff;
      :focus {
        border-color: #15112c;
        box-shadow: none;
      }
      &::placeholder {
        color: #ffffff90;
      }
    }
    button {
      border: none;
      width: 40px;
      height: 40px;
      background: url(/icons/search-icon.svg);
      background-repeat: no-repeat;
      background-size: 52%;
      background-position: center;
      span {
        // display: none;
        opacity: 0;
      }
      :hover,
      :focus {
        background: url(/icons/search-icon.svg) !important;
        width: 40px !important;
        height: 40px !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: 52% !important;
        border-color: #15112c;
        box-shadow: none;
      }
    }
  }
  .ant-input-group-addon {
    background: #15112c;
    overflow: hidden;
    width: 50px;
    :last-child {
      left: 0px !important;
    }
  }
`;

const NotificationArea = styled("div")`
  width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1em;
  a {
    margin-left: auto;
    position: relative;
    span {
      position: absolute;
      top: -5px;
      right: -8px;
      background: rgb(251, 131, 131);
      color: #ffffff;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      border-radius: 50%;
    }
  }
`;
const AlertModal = styled("div")`
  position: absolute;
  right: 6%;
  top: 7%;
  z-index: 11111;
`;
