import apiRequest from "../../api/apiRequest";
import { message } from "antd";
import {
  SET_USERS,
  SET_SINGLE_USER,
  SET_ADMINS,
  SET_DASHBOARD,
  SET_CUSTOMCHANEEL,
  SET_CATEGORIES,
  SET_PROMOTIONS,
  ADD_PROMOTION,
  UPDATE_PROMOTION,
  SEND_NOTIFICATIONS,
  SEARCH_USERS,
  SET_NOTIFICATIONS,
  SET_NOTIFICATION_TEMPLATES,
  ADD_NOTIFICATION_TEMPLATE,
  UPDATE_NOTIFICATION_TEMPLATE,
  DELETE_NOTIFICATION_TEMPLATE,
} from "../Constants";
export const getUsers =
  (setLoading, page, count, query, type) => (dispatch) => {
    const config = {
      method: "get",
      url: `/users?page=${page}&count=${count}&type=${type}${
        query && `&q=${query}`
      }`,
    };
    apiRequest(config)
      .then((res) => {
        console.log(res);
        setLoading(false);
        dispatch({ type: SET_USERS, payload: res.data });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

export const getUserPlayHistory =
  (params, userID, setLoading) => (dispatch) => {
    const config = {
      method: "get",
      url: `/playback-history/by-user/${userID}`,
      params,
    };
    apiRequest(config)
      .then((res) => {
        setLoading(false);
        dispatch({ type: SET_SINGLE_USER, payload: res.data });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

export const getCategories = (setLoading) => (dispatch) => {
  const config = {
    method: "get",
    url: `/categories`,
  };
  apiRequest(config)
    .then((res) => {
      setLoading(false);
      dispatch({ type: SET_CATEGORIES, payload: res.data });
    })
    .catch((err) => {
      setLoading(false);
    });
};

export const getAdmins = (setLoading, page, count) => (dispatch) => {
  const config = {
    method: "get",
    url: `/admins?page=${page}&count=${count}`,
  };
  apiRequest(config)
    .then((res) => {
      setLoading(false);
      dispatch({ type: SET_ADMINS, payload: res.data });
    })
    .catch((err) => {
      setLoading(false);
    });
};
export const getDashboardData = (setLoading) => (dispatch) => {
  const config = {
    method: "get",
    url: `/dashboard/counts`,
  };
  apiRequest(config)
    .then((res) => {
      setLoading(false);
      dispatch({ type: SET_DASHBOARD, payload: res.data });
    })
    .catch((err) => {
      setLoading(false);
    });
};
export const addAdmin = (setLoading, data) => (dispatch) => {
  const config = {
    method: "post",
    url: `/admins`,
    data,
  };
  apiRequest(config)
    .then((res) => {
      setLoading(false);
      // dispatch({ type: SET_ADMINS, payload: res.data });
    })
    .catch((err) => {
      setLoading(false);
    });
};
export const editAdmin = (setLoading, id, data) => (dispatch) => {
  const config = {
    method: "put",
    url: `/admins/${id}`,
    data,
  };
  apiRequest(config)
    .then((res) => {
      setLoading(false);
      // dispatch({ type: SET_ADMINS, payload: res.data });
    })
    .catch((err) => {
      setLoading(false);
    });
};
export const deleteAdmins = (setLoading, id) => (dispatch) => {
  const config = {
    method: "delete",
    url: `/admins/${id}`,
  };
  apiRequest(config)
    .then((res) => {
      setLoading(false);
      // dispatch({ type: SET_ADMINS, payload: res.data });
    })
    .catch((err) => {
      setLoading(false);
    });
};

export const getCustomChannels = (setLoading, params) => (dispatch) => {
  const config = {
    method: "get",
    url: `/custom-channels`,
    params,
  };
  apiRequest(config)
    .then((res) => {
      setLoading(false);
      dispatch({ type: SET_CUSTOMCHANEEL, payload: res.data });
    })
    .catch((err) => {
      setLoading(false);
    });
};

export const downloadCSV = () => (dispatch) => {
  const config = {
    method: "get",
    url: "/users/export",
    responseType: "text",
  };

  dispatch({ type: "DOWNLOAD_CSV_START" });

  return new Promise((resolve, reject) => {
    apiRequest(config)
      .then((res) => {
        let csvData = res;

        console.log("Raw CSV Data:", res);

        csvData = csvData
          .split("\n")
          .map((row) =>
            row
              .split(",")
              .map((cell) =>
                cell.trim() === "undefined" || cell.trim() === "null"
                  ? "N/A"
                  : cell
              )
              .join(",")
          )
          .join("\n");

        console.log("Processed CSV Data:", csvData);

        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "users.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        dispatch({ type: "DOWNLOAD_CSV_SUCCESS" });
        resolve();
      })
      .catch((err) => {
        console.error("Error during CSV download:", err.message);
        dispatch({ type: "DOWNLOAD_CSV_FAILURE", payload: err.message });
        reject(err);
      });
  });
};

export const getPromotions = (setLoading) => (dispatch) => {
  const config = {
    method: "get",
    url: `/promotion-codes`,
  };
  apiRequest(config)
    .then((res) => {
      setLoading(false);
      dispatch({ type: SET_PROMOTIONS, payload: res.data });
    })
    .catch((err) => {
      setLoading(false);
    });
};

export const createPromotion = (setLoading, data) => (dispatch) => {
  const config = {
    method: "post",
    url: `/promotion-codes`,
    data,
  };
  return apiRequest(config)
    .then((res) => {
      setLoading(false);
      dispatch({ type: ADD_PROMOTION, payload: res.data });
      message.success("Promotion created successfully!");
      return res; // Return the response to allow chaining
    })
    .catch((err) => {
      message.error(err.data || "An error occurred");
      setLoading(false);
      throw err; // Re-throw to allow handleCreate to catch it
    });
};

export const updatePromotion = (setLoading, id, data) => (dispatch) => {
  const config = {
    method: "put",
    url: `/promotion-codes/${id}`,
    data,
  };
  apiRequest(config)
    .then((res) => {
      setLoading(false);
      dispatch({ type: UPDATE_PROMOTION, payload: res.data });
      message.success("Promotion updated successfully!");
    })
    .catch((err) => {
      setLoading(false);
    });
};

export const sendPushNotifications = (setLoading, data) => (dispatch) => {
  const config = {
    method: "post",
    url: `/push-notifications`, // API endpoint for sending notifications
    data,
  };

  return apiRequest(config)
    .then((res) => {
      setLoading(false);
      dispatch({ type: SEND_NOTIFICATIONS, payload: res.data });
    })
    .catch((err) => {
      setLoading(false);
      message.error("Failed to send notifications.");
    });
};
export const searchUsers = (setLoading, query) => (dispatch) => {
  const config = {
    method: "get",
    url: `/users/search?q=${query}`,
  };

  setLoading(false);

  apiRequest(config)
    .then((res) => {
      setLoading(false);
      dispatch({ type: "SEARCH_USERS", payload: res.data });
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};

export const fetchNotifications =
  (setLoading, page = 1, count = 10) =>
  (dispatch) => {
    const config = {
      method: "get",
      url: `/push-notifications?page=${page}&count=${count}`,
    };

    apiRequest(config)
      .then((res) => {
        setLoading(false);
        const notifications = res.data.adminNotifications || [];
        const total = res.data.total || 0;
        dispatch({
          type: "SET_NOTIFICATIONS",
          payload: { notifications, total },
        });
      })
      .catch((err) => {
        setLoading(false);
        console.error("Failed to fetch notifications:", err);
      });
  };

export const fetchNotificationTemplates =
  (setLoading, page = 1, count = 10) =>
  (dispatch) => {
    const config = {
      method: "get",
      url: `/notification-templates?page=${page}&count=${count}`,
    };
    apiRequest(config)
      .then((res) => {
        setLoading(false);
        dispatch({ type: "SET_NOTIFICATION_TEMPLATES", payload: res.data });
      })
      .catch((err) => {
        setLoading(false);
        console.error("Failed to fetch notification templates:", err);
      });
  };

// Create Notification Template
export const createNotificationTemplate = (setLoading, data) => (dispatch) => {
  const config = {
    method: "post",
    url: `/notification-templates`,
    data,
  };
  return apiRequest(config)
    .then((res) => {
      setLoading(false);
      dispatch({ type: ADD_NOTIFICATION_TEMPLATE, payload: res.data });
      return res;
    })
    .catch((err) => {
      message.error(err.data || "An error occurred");
      setLoading(false);
      throw err;
    });
};

// Update Notification Template
export const updateNotificationTemplate =
  (setLoading, id, data) => (dispatch) => {
    const config = {
      method: "put",
      url: `/notification-templates/${id}`,
      data,
    };
    apiRequest(config)
      .then((res) => {
        setLoading(false);
        dispatch({ type: UPDATE_NOTIFICATION_TEMPLATE, payload: res.data });
      })
      .catch((err) => {
        setLoading(false);
        console.error("Failed to update notification template:", err);
      });
  };

export const deleteNotificationTemplate = (setLoading, id) => (dispatch) => {
  setLoading(true);

  return apiRequest({
    method: "DELETE",
    url: `/notification-templates/${id}`,
  })
    .then((res) => {
      setLoading(false);
      dispatch({ type: "DELETE_NOTIFICATION_TEMPLATE", payload: id });
      return res;
    })
    .catch((err) => {
      setLoading(false);
      console.error("Failed to delete template:", err.response || err);
      throw err;
    });
};
