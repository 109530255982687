// Authentication Types
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";

// Login Types
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

// Login Types
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

// Logout Types
export const LOGOUT = "LOGOUT";
export const SET_USERS = "SET_USERS";
export const SET_SINGLE_USER = "SET_SINGLE_USER";
export const SET_ADMINS = "SET_ADMINS";
export const SET_DASHBOARD = "SET_DASHBOARD";
export const SET_CUSTOMCHANEEL = "SET_CUSTOMCHANEEL";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_PROMOTIONS = "SET_PROMOTIONS";
export const UPDATE_PROMOTION = "UPDATE_PROMOTION";
export const ADD_PROMOTION = "ADD_PROMOTION";
export const SEND_NOTIFICATIONS = "SEND_NOTIFICATIONS";
export const SEARCH_USERS = "SEARCH_USERS";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_NOTIFICATION_TEMPLATES = "SET_NOTIFICATION_TEMPLATES";
export const ADD_NOTIFICATION_TEMPLATE = "ADD_NOTIFICATION_TEMPLATE";
export const UPDATE_NOTIFICATION_TEMPLATE = "UPDATE_NOTIFICATION_TEMPLATE";
export const DELETE_NOTIFICATION_TEMPLATE = "DELETE_NOTIFICATION_TEMPLATE";
