import axios from "axios";
const apiRequest = (options) =>
  new Promise((resolve, reject) => {
    axios({
      baseURL: process.env.REACT_APP_BACKEND_URL,
      ...options,
    })
      .then((response) => resolve(response.data))
      .catch((error) => {
        if (error.response.data.data) {
          // error from server
        } else {
          // error in request
          console.error("Error Message:", error.message);
        }
        if (error.response.status === 401) {
          return reject("Unauthorized");
        }
        reject(error.response.data || error.message);
      });
  });

export default apiRequest;
