import { Button, Col, DatePicker, Form, Row, Select, TimePicker } from "antd";
import React, { useState } from "react";
import { CreateModal, FormFooter } from "./index.styled";
import CustomInput from "../CustomInput/CustomInput";
import PasswordInput from "../PasswordInput/PasswordInput";
import { useDispatch } from "react-redux";
import { addAdmin } from "../../redux/action/appActions";

const AddAdmin = ({ visible, setVisible, titleName, fetchData }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const callback = () => {
    setLoading(false);
    form.resetFields();
    setVisible(false);
    fetchData();
  };

  const handleSubmit = (value) => {
    setLoading(true);
    dispatch(addAdmin(callback, value));
  };

  return (
    <CreateModal
      footer={false}
      title={<h3>Create {titleName}</h3>}
      open={visible}
      onCancel={setVisible}
      width="700px"
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={16}>
          <Col span={24}>
            <CustomInput label={`${titleName} Name`} name="name" />
          </Col>
          <Col span={24}>
            <CustomInput label={`Email`} name="email" />
          </Col>
          <Col span={24}>
            <Form.Item
              label="Role"
              name="role"
              rules={[
                {
                  required: true,
                  message: `Please select your role!`,
                },
              ]}
            >
              <Select placeholder="Role">
                <Select.Option value="superadmin">Super Admin</Select.Option>
                <Select.Option value="admin">Admin</Select.Option>
                {/* <Select.Option value="moderator">Moderator</Select.Option> */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <PasswordInput label="Password" name="password" />
          </Col>
          <FormFooter style={{ borderTop: "none", width: "100%" }}>
            <Button loading={loading} htmlType="submit">
              Add
            </Button>
          </FormFooter>
        </Row>
      </Form>
    </CreateModal>
  );
};

export default AddAdmin;
