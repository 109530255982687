import styled from "styled-components";

export const Wrapper = styled("div")`
  background: #030118;
  height: 100vh;
  overflow: hidden;
  @media (max-width: 992px) {
    & {
      background: unset;
      background-image: linear-gradient(-45deg, #000000, #000000, #08024a);
    }
  }
`;
export const Content = styled("div")`
  display: flex;
  height: 97vh;
  .mobileTable {
    overflow-x: auto;
    border-radius: 12px;
    background: #15112c;
    padding-bottom: 1em;
    &::-webkit-scrollbar-thumb {
      background: #888 !important;
    }
    &::-webkit-scrollbar {
      // width: 9px !important;
      height: 5px !important;
    }
    @media (max-width: 768px) {
      & {
        table {
          width: 500px !important;
        }
      }
    }
  }
`;
export const Container = styled("div")`
  width: 100%;
  // height: 97vh;
  overflow-y: scroll;
  padding: 3em 1em;
  padding-bottom: 4em !important;
  color: #ffffff;
  @media (max-width: 768px) {
    padding: 1em 1em;
    padding-bottom: 2em !important;
  }
`;
