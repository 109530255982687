import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Wrapper, Content, Container, MobileSidebar } from "./index.style";
import Header from "../components/header";
import Sidebar from "../components/sidebar";
import useWindowDimensions from "../hooks/Dimension";
import MobileHeader from "../components/mobileHeader";
import { useSelector } from "react-redux";
import { ROUTES } from "../constant";
import { Spin } from "antd";

const UserLayout = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const { width, height } = useWindowDimensions();
  const { user, loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (width <= 993) {
      setShowSidebar(true);
      setShowHeader(true);
    } else {
      setShowSidebar(false);
      setShowHeader(false);
    }
  }, [width]);
  const handleCloseSideNav = () => setShowSidebar(!showSidebar);
  const handelCloseSidebar = () => {
    setShowSidebar(false);
  };

  useEffect(() => {
    if (!user && !loading) {
      navigate(ROUTES.LOGIN);
    }
  }, [user]);

  return (
    <Spin spinning={loading} size="large">
      <Wrapper>
        {showHeader ? (
          <MobileHeader
            onShow={handleCloseSideNav}
            visible={showSidebar}
            showSidebar={setShowSidebar}
          />
        ) : (
          <Header />
        )}
        <Content>
          {showSidebar ? (
            ""
          ) : (
            <Sidebar
              userType="user"
              visible={showSidebar}
              setVisible={handelCloseSidebar}
            />
          )}

          <Container>
            <Outlet />
          </Container>
        </Content>
      </Wrapper>
    </Spin>
  );
};

export default UserLayout;
