import moment from "moment";
import React, { useEffect, useState } from "react";
import CustomTable from "../../components/customTable";
import {
  CollapseData,
  CollapseHeader,
  SearchArea,
  TabItem,
  Tabs,
  Wrapper,
} from "./index.styled";
import { Collapse, Input, Button, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constant";
import { getUsers, downloadCSV } from "../../redux/action/appActions";
import { useDispatch, useSelector } from "react-redux";
import { TableAction } from "../../components/customTable/index.styled";
import CustomPagination from "../../components/customPagination/CustomPagination";

const { Search } = Input;

const Users = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.app.users);
  const { user } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [activeTab, setActiveTab] = useState("all");

  const [data, setData] = useState([]);

  const columns = [
    {
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
      render: (text) => text !== "" && text !== "undefined" && text,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Subscription",
      dataIndex: "subscription",
      key: "subscription",
      render: (array) => array[0],
    },
    {
      title: "Join Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      title: "Actions",
      dataIndex: "playbackCount",
      key: "playbackCount",
      render: (playbackCount, record) => (
        <TableAction
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          <span
            style={{
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              backgroundColor: "yellow",
              color: "black",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              fontWeight: "bold",
              fontSize: "14px",
            }}
            onClick={() =>
              navigate(`${ROUTES.USER_ACTIVITY}?userId=${record._id}`, {
                state: record._id,
              })
            }
          >
            {playbackCount}
          </span>
          <span
            style={{
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              backgroundColor: "green",
              color: "black",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              fontWeight: "bold",
              fontSize: "14px",
            }}
            onClick={() =>
              navigate(`${ROUTES.CUSTOMCHANNELS}?userId=${record._id}`, {
                state: record._id,
              })
            }
          >
            {record.customChannelCount}
          </span>

          {/* New Hotjar Circle */}
          <span
            style={{
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              backgroundColor: "blue",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              fontWeight: "bold",
              fontSize: "14px",
            }}
            onClick={() =>
              window.open(
                `https://insights.hotjar.com/sites/3809327/playbacks?sort_by=-relevance_score&date={"DAYS_AGO":{"created":90}}&segment=1258657&filters={"EQUAL":{"user_attributes.email.email":"${record.email}"}}`,
                "_blank"
              )
            }
          >
            H
          </span>
        </TableAction>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: (
        <CollapseHeader>
          <h1>
            User List <span>{`(${users?.total || 0})`}</span>
          </h1>
          <span>view all</span>
        </CollapseHeader>
      ),
      children: (
        <>
          <CustomTable data={data} columns={columns} loading={loading} />
          <CustomPagination
            total={users?.total || 0}
            handleLoadData={(page, count) => {
              fetchData(page, count, searchKeyword, activeTab);
            }}
          />
        </>
      ),
    },
  ];

  const fetchData = (page, count, query, type) => {
    setLoading(true);
    dispatch(getUsers(setLoading, page, count, query, type));
  };

  const onSearch = (value) => setSearchKeyword(value);

  const handleDownloadCSV = () => {
    setCsvLoading(true); // Start loading spinner

    dispatch(downloadCSV())
      .then(() => {
        notification.success({
          message: "Download Successful",
          description: "The CSV file has been downloaded successfully.",
        });
      })
      .catch((err) => {
        notification.error({
          message: "Download Failed",
          description: `Failed to download CSV file: ${err.message}`,
        });
      })
      .finally(() => {
        setCsvLoading(false);
      });
  };

  useEffect(() => {
    if (user) {
      fetchData(1, 10, searchKeyword, activeTab);
    }
  }, [searchKeyword, activeTab, user]);

  useEffect(() => {
    if (users) {
      setData(users.users);
    }
  }, [users]);

  return (
    <>
      <SearchArea>
        <Search placeholder="Search" onSearch={onSearch} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Tabs>
            <TabItem
              style={{ textDecoration: activeTab === "all" && "underline" }}
              onClick={() => setActiveTab("all")}
            >
              All
            </TabItem>
            <TabItem
              style={{ textDecoration: activeTab === "users" && "underline" }}
              onClick={() => setActiveTab("users")}
            >
              Users
            </TabItem>
            <TabItem
              style={{ textDecoration: activeTab === "fakes" && "underline" }}
              onClick={() => setActiveTab("fakes")}
            >
              Fakes
            </TabItem>
          </Tabs>
          <Button
            type="primary"
            onClick={handleDownloadCSV}
            loading={csvLoading}
          >
            Download CSV
          </Button>
        </div>
      </SearchArea>
      <Wrapper>
        <div className="tabContainer">
          <CollapseData>
            <Collapse items={items} defaultActiveKey={1} />
          </CollapseData>
        </div>
      </Wrapper>
    </>
  );
};

export default Users;
