import styled from "styled-components";

// Existing components
export const PushNotificationsWrapper = styled.div`
  padding: 20px;
  background-color: #15112c;
`;

export const SectionTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 25px;
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  color: white;
  font-weight: bold;
  margin-bottom: 5px;
  display: inline-block;
`;

export const Input = styled.input`
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
`;

export const DescriptionInput = styled.textarea`
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 150px;
`;

export const UserButton = styled.button`
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: ${(props) => (props.selected ? "#0052cc" : "#f7f8fc")};
  color: ${(props) => (props.selected ? "#fff" : "#000")};
`;

export const SendButton = styled.button`
  background-color: ${(props) =>
    props.disabled
      ? "#d3d3d3"
      : "#007BFF"}; // Ash color for disabled, blue for normal
  color: ${(props) =>
    props.disabled ? "#999" : "#fff"}; // Light gray text when disabled
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: ${(props) =>
    props.disabled
      ? "not-allowed"
      : "pointer"}; // Change cursor to 'not-allowed' when disabled
  opacity: ${(props) =>
    props.disabled ? 0.7 : 1}; // Slightly transparent when disabled

  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? "#d3d3d3"
        : "#0056b3"}; // Darker blue on hover if not disabled
  }
`;

export const RadioButtonGroup = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  input[type="radio"] {
    margin-right: 5px; /* Adds spacing between radio button and label */
  }
`;

export const SuggestionList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc; /* Add border for better visibility */
  border-radius: 4px;
  background-color: white; /* Ensure background is white */
  color: black; /* Ensure text color is black */
`;

export const SuggestionItem = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  color: black; /* Set text color to black */
  background-color: white; /* Set background to white */

  &:hover {
    background-color: #f0f0f0; /* Change background on hover */
  }
`;

// New components added based on the error

export const HistoryTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f2f2f2;
  }
`;

export const TabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const TabButton = styled.button`
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: ${(props) => (props.active ? "#007BFF" : "#f7f8fc")};
  color: ${(props) => (props.active ? "#fff" : "#000")};
`;

export const TabContent = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

export const Tab = styled.button`
  padding: 10px 20px;
  background-color: ${(props) => (props.active ? "#f0f0f0" : "transparent")};
  color: ${(props) => (props.active ? "black" : "#888")};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  border: none;
  border-bottom: ${(props) =>
    props.active ? "2px solid black" : "2px solid transparent"};
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #e0e0e0;
    color: black;
  }
`;

// export const PaginationControls = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin-top: 20px;
//   align-items: center;

//   button {
//     background-color: #007bff;
//     color: white;
//     border: none;
//     padding: 10px 20px;
//     font-size: 14px;
//     cursor: pointer;
//     border-radius: 5px;

//     &:disabled {
//       background-color: #ccc;
//       cursor: not-allowed;
//     }
//   }

//   span {
//     font-size: 14px;
//     margin: 0 10px;
//   }
// `;

export const CollapseHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  margin-right: 1.5em;
  h1 {
    margin: 0;
    font-size: 1.3em;
    font-weight: 500;
    text-transform: uppercase;
    span {
      font-size: 0.8em;
      font-weight: 400;
    }
  }
  span {
    font-size: 0.9em;
  }
`;
export const SearchArea = styled("div")`
  // width: 67%;
  margin: auto;
  .ant-input-search {
    background: #15112c;
  }
  .ant-input-wrapper {
    border-radius: 40px;
    background: #15112c;
    color: #ffffff;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row-reverse !important;
    overflow: hidden;
    height: 50px;
    padding-left: 0.5em;
    input {
      border: none;
      font-size: 1em;
      background: #15112c;
      color: #ffffff;
      :focus {
        border-color: #15112c;
        box-shadow: none;
      }
      &::placeholder {
        color: #ffffff90;
      }
    }
    button {
      border: none;
      width: 40px;
      height: 40px;
      background: url(/icons/search-icon.svg);
      background-repeat: no-repeat;
      background-size: 52%;
      background-position: center;
      span {
        // display: none;
        opacity: 0;
      }
      &:hover,
      &:focus {
        background: url(/icons/search-icon.svg) !important;
        width: 40px !important;
        height: 40px !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: 52% !important;
        // background-color: transparent;
        border-color: #15112c;
        box-shadow: none;
      }
    }
  }
  .ant-input-group-addon {
    background: #15112c;
    overflow: hidden;
    width: 50px;
    :last-child {
      left: 0px !important;
    }
  }
`;
export const Tabs = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;
export const TabItem = styled.h3`
  margin: 0;
  font-size: 1.3em;
  font-weight: 500;
  cursor: pointer;
`;
export const Wrapper = styled("div")`
  padding-bottom: 6em;
  margin-top: 3em;

  .template-table-container {
    display: flex;
    justify-content: flex-end; /* Button on right */
    align-items: center;
    margin-bottom: 20px; /* Add margin between the button and the table */
  }

  .add-template-btn {
    margin-left: auto;
    margin-right: 80px; /* Optional: Adjust spacing from the edge */
  }

  .hideData {
    display: none;
    @media (max-width: 768px) {
      & {
        display: block;
      }
    }
  }

  .showData {
    display: block;
    @media (max-width: 768px) {
      & {
        display: none;
      }
    }
  }

  .mobileTable {
    overflow-x: auto;
    padding-bottom: 1em;
    &::-webkit-scrollbar-thumb {
      background: #888 !important;
    }
    &::-webkit-scrollbar {
      height: 5px !important;
    }
  }

  .tabContainer {
    border-radius: 12px;
    background: #15112c;
    padding: 0em 1em;
    .ant-collapse {
      .ant-table-thead {
        .ant-table-cell {
          background: #00000000 !important;
          padding-left: 0em !important;
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          background: #00000000 !important;
          padding-left: 0em !important;
        }
      }
    }
    .ant-collapse-header {
      padding: 1em 0 !important;
      .ant-collapse-expand-icon {
        right: -15px !important;
      }
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 2em !important;
  }
`;

export const CollapseData = styled("div")`
  width: 100%;
  .ant-collapse {
    border: none;
    background: transparent !important;
    width: 100%;
  }
  .ant-collapse-item {
    border: none;
    border-radius: 12px 12px 0 0 !important;
    background: #15112c;
    box-shadow: 0px 1px 3px 0px #00000014;
    margin-bottom: 1em;
    overflow: hidden;
    border-bottom: 1px solid #ffffff90;
    .ant-collapse-header {
      background: #15112c;
      color: #ffffff;
      .ant-collapse-expand-icon {
        // display: none;
        position: absolute;
        right: 0;
        top: 23px;
        svg {
          font-size: 1.5em;
          transform: rotate(90deg);
        }
      }
    }
    .ant-collapse-content,
    .ant-collapse-content-active {
      background: #15112c;
      color: #ffffff;
    }
  }
  .ant-collapse-item-active {
    border-radius: 12px !important;
    border-bottom: none !important;
    .ant-collapse-header {
      .ant-collapse-expand-icon {
        svg {
          transform: rotate(-90deg) !important;
        }
      }
    }
  }
  .ant-collapse-content {
    border-top: 1px solid #ffffff90;
  }
  .ant-collapse-content-box {
    padding: 0.5em 0 2em !important;
  }
`;
export const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    background-color: #003366; /* Dark blue */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }

  span {
    font-size: 14px;
    margin: 0 10px;
  }

  .current {
    background-color: #000080; /* Lighter blue for current page */
    color: white;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end; /* Align the button to the right */
  margin-bottom: 20px; /* Add some margin at the bottom */
`;