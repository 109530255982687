import { AutoComplete, Input } from "antd";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { FaChevronLeft } from "react-icons/fa";

const { Search } = Input;

const MobileHeader = ({
  onShow,
  visible,
  showSidebar,
  profileAddress,
  userType,
}) => {
  const [showNotification, setShowNotification] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [options, setOptions] = React.useState([]);
  const handleSearch = (value) => {
    setOptions(() => {
      // if (!value || value.includes("@")) {
      //   return [];
      // }
      return ["John doe", "Alex John"].map((domain) => ({
        label: (
          <SearchItem>
            {domain && <img src="/images/user.png" alt="" />}
            {domain}
          </SearchItem>
        ),
      }));
    });
  };

  const onSearch = (value, _e, info) => {
    console.log(info?.source, value);
    setShowSearch(false);
  };
  return (
    <HeaderContainer>
      <div className="topContent">
        <LogoArea>
          <span onClick={onShow}>
            {visible === true ? (
              <img src="/icons/top-bar-icon.svg" alt="" />
            ) : (
              <FaChevronLeft style={{ color: "#ffffff" }} />
            )}
          </span>
          <img src="/icons/mobile-logo.svg" alt="" />
        </LogoArea>
        {/* <img src="/icons/search-icon.svg" alt="" /> */}
        <NotificationArea>
          <NavLink
            to="#"
            onClick={(e) => {
              setShowSearch(!showSearch);
              setShowNotification(false);
              showSidebar(true);
              e.preventDefault();
            }}
          >
            <img src="/icons/search-icon.svg" alt="" />
          </NavLink>

          <NavLink
            to="#"
            onClick={(e) => {
              setShowNotification(!showNotification);
              e.preventDefault();
            }}
          >
            <span>6</span>
            <img src="/icons/bell-icon.svg" alt="" />
          </NavLink>
          <NavLink to="/user_chat">
            <span>4</span>
            <img src="/icons/message-icon.svg" alt="" />
          </NavLink>
          <NavLink to={profileAddress ? `${profileAddress}` : "/profile"}>
            <img src="/images/user.png" alt="" />
          </NavLink>
        </NotificationArea>
      </div>
      {showSearch && (
        <SearchArea>
          <AutoComplete onSearch={handleSearch} options={options}>
            <Search placeholder="Search" onSearch={onSearch} />
          </AutoComplete>
        </SearchArea>
      )}
    </HeaderContainer>
  );
};

export default MobileHeader;

const HeaderContainer = styled("header")`
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // gap: 1em;
  border-bottom: 1px solid #ffffff;
  padding: 1em 1em;
  background: #15112c;
  height: auto;
  // box-shadow: 1px 0px 10px 0px #000000;
  .topContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
  }
`;
const LogoArea = styled("div")`
  background: #15112c;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 40px;
  span {
    width: 30px;
    cursor: pointer;
    margin-right: 1em;
  }
  // @media (max-width: 1600px) {
  //   & {
  //     width: 335px;
  //   }
  // }
`;
const SearchArea = styled("div")`
  width: 100%;
  margin: 1em auto 0;
  .ant-select {
    width: 100%;
  }
  .ant-select-single {
    height: unset;
  }
  .ant-input-search {
    background: #15112c;
  }
  .ant-input-wrapper {
    border-radius: 40px;
    background: #15112c;
    color: #ffffff;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: start;
    overflow: hidden;
    height: 50px;
    padding-left: 0.5em;
    input {
      border: none;
      font-size: 1em;
      background: #15112c;
      color: #ffffff;
      :focus {
        border-color: #15112c;
        box-shadow: none;
      }
      &::placeholder {
        color: #ffffff90;
      }
    }
    button {
      border: none;
      width: 40px;
      height: 40px;
      background: url(/icons/closed-icon.svg);
      background-repeat: no-repeat;
      background-size: 52%;
      background-position: center;
      span {
        // display: none;
        opacity: 0;
      }
      :hover,
      :focus {
        background: url(/icons/closed-icon.svg) !important;
        width: 40px !important;
        height: 40px !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: 52% !important;
        border-color: #15112c;
        box-shadow: none;
      }
    }
  }
  .ant-input-group-addon {
    background: #15112c;
    overflow: hidden;
    width: 50px;
    :last-child {
      left: 0px !important;
    }
  }
`;

const NotificationArea = styled("div")`
  width: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0em 0em;
  a {
    margin-left: auto;
    position: relative;
    span {
      position: absolute;
      top: -0px;
      right: -2px;
      background: rgb(251, 131, 131);
      color: #ffffff;
      width: 10px;
      height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 7px;
      border-radius: 50%;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
`;
const AlertModal = styled("div")`
  position: absolute;
  right: 6%;
  top: 7%;
  z-index: 11111;
`;
const SearchItem = styled("div")`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1em;
`;
