import apiRequest from "../../api/apiRequest";
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGOUT,
} from "../Constants";
import setAuthToken from "../../helpers/setAuthToken";
import { message } from "antd";

// Load User Action
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token); // Set token in headers if exists
  }

  try {
    const response = await apiRequest({
      method: "get",
      url: "/auth/me", // Replace with your actual user profile API
    });

    dispatch({
      type: USER_LOADED,
      payload: response.data, // Load user details
    });
  } catch (error) {
    console.error("Load user error:", error.response?.data || error.message);
    dispatch({
      type: AUTH_ERROR, // Dispatch AUTH_ERROR if token is invalid or API fails
    });
  }
};

// Login User Action
export const login = (data, setLoading) => {
  return async function (dispatch) {
    try {
      const response = await apiRequest({
        method: "post",
        url: "/auth/login",
        data,
        headers: {
          "Content-Type": "application/json", // Ensure correct headers are set
        },
      });

      // Destructure token and admin from the response
      const { token, admin } = response; // Response does not have "data" since it's the full object

      if (token) {
        // Store the token in localStorage
        localStorage.setItem("token", token);

        // Set the token to headers for future requests
        setAuthToken(token);

        // Dispatch LOGIN_SUCCESS with token and user details (admin)
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { token, user: admin }, // Pass both token and user data
        });

        console.log("Login successful:", response); // Debugging
      } else {
        throw new Error("Token not found in response");
      }

      setLoading(false);
    } catch (error) {
      console.error("Login error:", error.response?.data || error.message); // Detailed error logging
      message.error(error.response?.data?.message || "Login failed");
      setLoading(false);
      dispatch({ type: LOGIN_FAIL });
    }
  };
};

// Change Password Action
export const changePassword = (data, setLoading) => async (dispatch) => {
  try {
    const response = await apiRequest({
      method: "post",
      url: "/auth/change-password", // Replace with actual change-password API
      data,
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log("Password changed successfully:", response.data);
    setLoading(false);

    // After password change, log out the user
    dispatch(logout());
  } catch (error) {
    console.error(
      "Change password error:",
      error.response?.data || error.message
    );
    message.error(error.response?.data?.message || "Password change failed");
    setLoading(false);
  }
};

// Logout Action
export const logout = () => (dispatch) => {
  localStorage.removeItem("token"); // Clear token from localStorage
  setAuthToken(null); // Clear token from headers
  dispatch({ type: LOGOUT });
};
