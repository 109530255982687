import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Layout from "../layout";
import Login from "../screens/login";
import Admin from "../screens/admin";
import Dashboard from "../screens/dashboard";
import Users from "../screens/users";
import { ROUTES } from "../constant";
import Profile from "../screens/profile";
import UserActivity from "../screens/userActivity";
import CustomChannels from "../screens/customChannels";
import CategoriesPage from "../screens/categories";
import PromotionsPage from "../screens/promotions";
import PushNotifications from "../screens/pushNotifications";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route element={<Layout />}>
        <Route path={ROUTES.ROOT} element={<Dashboard />} />
        <Route path={ROUTES.ADMIN} element={<Admin />} />
        <Route path={ROUTES.USER} element={<Users />} />
        <Route path={ROUTES.PROFILE} element={<Profile />} />
        <Route path={ROUTES.USER_ACTIVITY} element={<UserActivity />} />
        <Route path={ROUTES.CUSTOMCHANNELS} element={<CustomChannels />} />
        <Route path={ROUTES.CATEGORIES} element={<CategoriesPage />} />
        <Route path={ROUTES.PROMOTIONS} element={<PromotionsPage />} />
        <Route
          path={ROUTES.PUSH_NOTIFICATIONS}
          element={<PushNotifications />}
        />
      </Route>
    </Routes>
  );
};

export default AllRoutes;
