import React, { useEffect, useState } from "react";
import CustomInput from "../../components/CustomInput/CustomInput";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import { Button, Col, Form, Row, Select, Upload } from "antd";
import {
  Avatar,
  ImageSection,
  UploadArea,
  Wrapper,
  FormFooter,
} from "./index.styled";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, logout } from "../../redux/action/authActions";
import { editAdmin } from "../../redux/action/appActions";

const Style = {
  paddingRight: "0",
  borderTop: "none",
};
const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const currentUser = useSelector((state) => state.auth.user);

  const callback = () => {
    // window.location.reload();
    setProfileLoading(false);
    dispatch(logout());
  };

  const handleProfileSubmit = (value) => {
    setProfileLoading(true);
    dispatch(editAdmin(callback, currentUser.id, value));
  };

  const handlePasswordSubmit = (value) => {
    setLoading(true);
    dispatch(
      changePassword(
        {
          currentPassword: value.currentPassword,
          newPassword: value.newPassword,
        },
        setLoading
      )
    );
  };

  useEffect(() => {
    if (currentUser) {
      form.setFieldsValue(currentUser);
    }
  }, [currentUser]);

  return (
    <Wrapper>
      <ImageSection>
        <h1>Profile settings</h1>
        <Form form={form} layout="vertical" onFinish={handleProfileSubmit}>
          <Row gutter={16}>
            {/* <Col xs={{ span: 24 }} md={{ span: 2 }}>
              <UploadArea>
                <Avatar>
                  <img src="/images/user-3.png" alt="" />
                </Avatar>
                <Upload>
                  <span>Click to Edit</span>
                </Upload>
              </UploadArea>
            </Col> */}
            <Col xs={{ span: 24 }}>
              <Row gutter={26}>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <CustomInput
                    label="Full name"
                    name="name"
                    placeholder="Enter Full Name"
                  />
                </Col>
                {/* <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <CustomInput
                    label="Email"
                    name="email"
                    placeholder="Enter New Email"
                  />
                </Col> */}
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item
                    label="Role"
                    name="role"
                    rules={[
                      {
                        required: true,
                        message: `Please select your role!`,
                      },
                    ]}
                  >
                    <Select placeholder="Role" disabled={true}>
                      <Select.Option value="superadmin">
                        Super Admin
                      </Select.Option>
                      <Select.Option value="admin">Admin</Select.Option>
                      {/* <Select.Option value="moderator">Moderator</Select.Option> */}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <FormFooter style={Style}>
            <Button htmlType="submit" loading={profileLoading}>
              Save
            </Button>
          </FormFooter>
        </Form>
      </ImageSection>
      <ImageSection>
        <h1>Change Password</h1>
        <Form layout="vertical" onFinish={handlePasswordSubmit}>
          <Row>
            <Col span={24}>
              <Row gutter={26}>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <PasswordInput
                    label="Current Password"
                    name="currentPassword"
                    placeholder="Enter Current Password"
                    rules={[
                      {
                        required: true,
                        message: `Please enter current Password!`,
                      },
                    ]}
                  />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <PasswordInput
                    label="New Password"
                    name="newPassword"
                    placeholder="New Password"
                    rules={[
                      {
                        required: true,
                        message: `Please enter new Password!`,
                      },
                    ]}
                  />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <PasswordInput
                    label="Re Enter New Password"
                    name="confirmPassword"
                    placeholder="Re Enter New Password"
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("newPassword") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The new password that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <FormFooter style={Style}>
            <Button htmlType="submit" loading={loading}>
              Save
            </Button>
          </FormFooter>
        </Form>
      </ImageSection>
      {/* <ImageSection>
        <h1>Change Email</h1>
        <Form layout="vertical">
          <Row gutter={26}>
            <Col xs={{ span: 24 }} md={{ span: 16 }}>
              <Row gutter={26}>
                
              </Row>
            </Col>
          </Row>
          <FormFooter style={Style}>
            <Button>Save</Button>
          </FormFooter>
        </Form>
      </ImageSection> */}
    </Wrapper>
  );
};

export default Profile;
