import styled from "styled-components";

export const Wrapper = styled("div")`
  h1 {
    color: #ffffff !important;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.3em;
  }

  label {
    color: #ffffff !important;
  }
  .selectionArea {
    padding: 1.5em;
    .ant-select-selector {
      border: 1px solid #ffffff !important;
      background: #15112c !important;
      border-radius: 50px;
      width: 100%;
      // height: 56px;
      padding: 1em;
      input {
        &:placeholder {
          color: #ffffff90 !important;
        }
      }
      .ant-select-selection-overflow-item {
        .ant-select-selection-item {
          background: #3a49d6 !important;
          border-radius: 20px;
        }
        .anticon-close {
          svg {
            color: #ffffff;
          }
        }
      }
    }
    .ant-select-arrow {
      .anticon {
        svg {
          color: #ffffff !important;
        }
      }
      .anticon-search {
        display: none;
        transform: rotateY(0deg) !important;
        svg {
          color: #ffffff !important;
          transform: rotateY(0deg) !important;
        }
      }
    }
    textarea,
    .ant-picker {
      border: 1px solid #ffffff;
      color: #ffffff;
      background: #15112c;
      width: 100%;
      border-radius: 40px;
      margin-right: 1em;
      height: 56px;
      input {
        ::placeholder {
          color: #ffffff90;
        }
      }
      .ant-picker-input {
        flex-direction: row-reverse;
      }
      .ant-picker-clear,
      .ant-picker-suffix {
        display: none;
        margin-right: 0.5em;
        svg {
          color: #ffffff;
        }
        &:hover {
          svg {
            color: #ffffff;
          }
        }
      }
      .ant-picker-clear {
        display: none;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      h1 {
        font-size: 1em;
      }
      .selectionArea {
        padding: 0.5em;
      }
    }
  }
`;
export const Card = styled("div")`
  background: #15112c;
  border-radius: 20px;
  padding: 1em 1.5em;
  margin-bottom: 1em;
  label {
    color: #ffffff !important;
  }
`;
