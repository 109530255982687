const Routes = {
  ROOT: "/",
  DASHBOARD: "/dashboard",
  ADMIN: "/admins",
  USER: "/users",
  PROFILE: "/profile",
  USER_ACTIVITY: "/play-back-history",
  LOGIN: "/login",
  CUSTOMCHANNELS: "/custom-channels",
  CATEGORIES: "/categories",
  PROMOTIONS: "/promotions",
  PUSH_NOTIFICATIONS: "/pushNotifications"
};

export default Routes;
