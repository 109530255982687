import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  message,
  Button,
  Modal,
  Table,
  Form,
  AutoComplete,
  Input as AntdInput,
} from "antd"; // Renamed Antd Input
import {
  sendPushNotifications,
  searchUsers,
  fetchNotifications,
  fetchNotificationTemplates,
  createNotificationTemplate,
  updateNotificationTemplate,
  deleteNotificationTemplate,
} from "../../redux/action/appActions";
import {
  PushNotificationsWrapper,
  SectionTitle,
  InputWrapper,
  Label,
  DescriptionInput,
  SendButton,
  RadioButtonGroup,
  SuggestionList,
  SuggestionItem,
  Wrapper,
  Input as StyledInput, // Renamed styled Input to avoid conflict
} from "./index.styled";
import { Tabs, Tab, Box, Paper } from "@mui/material";
import CustomTable from "../../components/customTable";
import CustomPagination from "../../components/customPagination/CustomPagination";

const PushNotifications = () => {
  const [form] = Form.useForm();
  const [selectedUserType, setSelectedUserType] = useState("All Users");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedUserIds, setSelectedUserIds] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [suggestionsVisible, setSuggestionsVisible] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [isTemplateModalVisible, setTemplateModalVisible] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state
  const [limitedUsers, setLimitedUsers] = useState([]); // Store users for limited-users modal

  const dispatch = useDispatch();

  // Fetch from Redux
  const availableUsers = useSelector((state) => state.app?.users || []);
  const notifications = useSelector((state) => state.app?.notifications || []);
  const totalNotifications = useSelector(
    (state) => state.app?.totalNotifications || 0
  );
  const templates = useSelector(
    (state) => state.app?.notificationTemplates || []
  );
  const totalTemplates = useSelector(
    (state) => state.app?.totalNotificationTemplates || 0
  );

  // Function to show limited users modal
  const showLimitedUsers = (users) => {
    setLimitedUsers(users);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false); // Hide the modal
  };

  // Handle user search query with debounce
  useEffect(() => {
    if (searchQuery.length > 2 && !selectedUser) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(searchUsers(setLoading, searchQuery));
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchQuery, dispatch, selectedUser]);

  useEffect(() => {
    if (tabValue === 1) {
      dispatch(fetchNotifications(setLoading, 1, 10));
    } else if (tabValue === 2) {
      dispatch(fetchNotificationTemplates(setLoading));
    }
  }, [tabValue, dispatch]);

  // Suggestion filtering based on title input
  const handleTitleChange = (value) => {
    setTitle(value);
    if (value) {
      const filtered = templates.filter((template) =>
        template.title.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredTemplates(filtered);
    } else {
      setFilteredTemplates([]);
    }
  };

  // When template is selected, auto-fill title and description
  const handleTemplateSelect = (value, option) => {
    const selectedTemplate = templates.find(
      (template) => template._id === option.key
    );
    if (selectedTemplate) {
      setTitle(selectedTemplate.title);
      setDescription(selectedTemplate.body);
    }
  };

  const handleSendNotification = () => {
    let users = [];

    if (selectedUserType === "Users by IDs") {
      if (!selectedUserIds.trim()) {
        message.error("Please enter valid user IDs.");
        return;
      }
      users = selectedUserIds.split(",").map((id) => id.trim());
    }

    if (selectedUserType === "Single User" && !selectedUser) {
      message.error("Please select a user from the suggestions");
      return;
    }

    const requestData = {
      title,
      description,
      type: selectedUserType === "All Users" ? "all-users" : "limited-users",
      users: selectedUserType === "Single User" ? [selectedUser] : users,
    };

    setLoading(true);

    dispatch(sendPushNotifications(setLoading, requestData))
      .then(() => {
        setLoading(false);
        message.success("Notification sent successfully!");
      })
      .catch(() => {
        setLoading(false);
        message.error("Failed to send notification.");
      });
  };

  const getDisplayName = (user) => {
    return user.fullname &&
      user.fullname !== "undefined" &&
      user.fullname !== "null"
      ? user.fullname
      : user.email;
  };

  const handleSuggestionClick = (user) => {
    setSelectedUser(user._id);
    setSearchQuery(getDisplayName(user));
    setSuggestionsVisible(false);
  };

  // Notification history table columns
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "body",
      key: "body",
    },
    {
      title: "Sent To",
      dataIndex: "sentTo",
      key: "sentTo",
      render: (sentTo, record) => {
        if (sentTo === "limited-users") {
          const userCount = record.users.length;
          return (
            <div
              onClick={() => showLimitedUsers(record.users)}
              style={{
                color: 'white',
                cursor: 'pointer',
                display: 'inline-block',
              }}
            >
              Limited Users [{userCount}]
            </div>
          );
        } else if (
          sentTo === "single-user" &&
          record.users &&
          record.users.length > 0
        ) {
          return (
            <div
              type="button"
              onClick={() => showLimitedUsers([record.users[0]])}
              style={{
                color: 'white',
                cursor: 'pointer',
                display: 'inline-block',
              }}
            >
              Single User
            </div>
          );
        } else if (sentTo === "all-users") {
          return <span>All Users</span>;
        }
        return sentTo;
      },
    },
    {
      title: "Sent By",
      dataIndex: "sentBy",
      key: "sentBy",
      render: (sentBy) => sentBy?.name ?? "Unknown",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).format("YYYY/MM/DD HH:mm:ss"),
    },
  ];

  const userColumns = [
    {
      title: "Full Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  const fetchData = (page, count) => {
    setLoading(true);
    dispatch(fetchNotifications(setLoading, page, count));
    setLoading(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAddTemplate = () => {
    setEditingTemplate(null);
    form.resetFields();
    setTemplateModalVisible(true);
  };

  const handleSaveTemplate = (values) => {
    setLoading(true);
    const action = editingTemplate
      ? updateNotificationTemplate(setLoading, editingTemplate._id, values)
      : createNotificationTemplate(setLoading, values);

    dispatch(action)
      .then(() => {
        message.success(
          editingTemplate ? "Template updated!" : "Template created!"
        );
        setTemplateModalVisible(false);
        dispatch(fetchNotificationTemplates(setLoading));
      })
      .catch(() => {
        message.error("Failed to save template");
        setLoading(false);
      });
  };

  const handleEditTemplate = (template) => {
    setEditingTemplate(template);
    form.setFieldsValue(template);
    setTemplateModalVisible(true);
  };

  const handleDeleteTemplate = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this template?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        return dispatch(deleteNotificationTemplate(setLoading, id))
          .then(() => {
            message.success("Template deleted successfully!");
            dispatch(fetchNotificationTemplates(setLoading));
          })
          .catch((error) => {
            console.error("Failed to delete template:", error);
            message.error("Failed to delete template.");
          });
      },
      onCancel() {
        message.info("Delete action canceled.");
      },
    });
  };

  const handleUserTypeChange = (event) => {
    const { value } = event.target;
    setSelectedUserType(value);
    setSelectedUser(null);
    setSelectedUserIds("");
    setSearchQuery("");
    setSuggestionsVisible(true);
  };

  const templateColumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "body",
      key: "body",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <EditOutlined
            style={{ fontSize: "18px", color: "#007BFF", cursor: "pointer" }}
            onClick={() => handleEditTemplate(record)}
          />
          <DeleteOutlined
            style={{ fontSize: "18px", color: "#ff4d4f", cursor: "pointer" }}
            onClick={() => handleDeleteTemplate(record._id)}
          />
        </div>
      ),
    },
  ];

  return (
    <Box sx={{ width: "100%", bgcolor: "#15112c", p: 2 }}>
      {/* Table for Notifications */}
      {/* <Table columns={columns} dataSource={notifications} rowKey="_id" /> */}

      {/* Modal for showing Limited Users */}
      <Modal
        title="Limited Users"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Table
          columns={userColumns}
          dataSource={limitedUsers}
          rowKey="_id"
          pagination={false}
        />
      </Modal>
      <Paper elevation={3} sx={{ mb: 2 }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Send Push Notifications" />
          <Tab label="History" />
          <Tab label="Template" />
        </Tabs>
      </Paper>

      <Box sx={{ bgcolor: "#15112c", p: 3, borderRadius: 2 }}>
        {/* Tab 0: Send Push Notifications */}
        {tabValue === 0 && (
          <PushNotificationsWrapper>
            <SectionTitle>Push Notifications</SectionTitle>
            <InputWrapper>
              <Label>User Group</Label>
              <RadioButtonGroup>
                <label>
                  <input
                    type="radio"
                    value="All Users"
                    checked={selectedUserType === "All Users"}
                    onChange={handleUserTypeChange}
                  />
                  All Users
                </label>
                <label>
                  <input
                    type="radio"
                    value="Users by IDs"
                    checked={selectedUserType === "Users by IDs"}
                    onChange={handleUserTypeChange}
                  />
                  Users by IDs
                </label>
                <label>
                  <input
                    type="radio"
                    value="Single User"
                    checked={selectedUserType === "Single User"}
                    onChange={handleUserTypeChange}
                  />
                  Single User
                </label>
              </RadioButtonGroup>
            </InputWrapper>

            {selectedUserType === "Users by IDs" && (
              <InputWrapper>
                <Label>Enter User IDs (comma-separated)</Label>
                <StyledInput
                  type="text"
                  value={selectedUserIds}
                  onChange={(e) => setSelectedUserIds(e.target.value)}
                  placeholder="Enter user IDs separated by commas"
                />
              </InputWrapper>
            )}

            {selectedUserType === "Single User" && (
              <InputWrapper>
                <Label>Search User</Label>
                <StyledInput
                  type="text"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setSuggestionsVisible(true);
                  }}
                  placeholder="Search by user name or email"
                />
                {searchQuery.length > 2 &&
                  availableUsers.length > 0 &&
                  suggestionsVisible && (
                    <SuggestionList>
                      {availableUsers.map((user) => (
                        <SuggestionItem
                          key={user._id}
                          onClick={() => handleSuggestionClick(user)}
                        >
                          {getDisplayName(user)} ({user.email})
                        </SuggestionItem>
                      ))}
                    </SuggestionList>
                  )}
                {searchQuery.length > 2 &&
                  availableUsers.length === 0 &&
                  suggestionsVisible && (
                    <SuggestionList>
                      <SuggestionItem>No users found</SuggestionItem>
                    </SuggestionList>
                  )}
              </InputWrapper>
            )}

            <InputWrapper>
              <Label>Title</Label>
              <AutoComplete
                options={filteredTemplates.map((template) => ({
                  label: template.title,
                  value: template.title,
                  key: template._id,
                }))}
                onSelect={handleTemplateSelect}
                onSearch={handleTitleChange}
                value={title}
                placeholder="Enter or select a notification title"
                style={{ width: "100%" }}
              >
                <AntdInput />
              </AutoComplete>
            </InputWrapper>

            <InputWrapper>
              <Label>Description</Label>
              <DescriptionInput
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter notification description"
              />
            </InputWrapper>

            <SendButton onClick={handleSendNotification} disabled={loading}>
              {loading ? "Sending..." : "Send Push Notification"}
            </SendButton>
          </PushNotificationsWrapper>
        )}

        {/* Tab 1: History */}
        {tabValue === 1 && (
          <Wrapper>
            <h2>Notifications History</h2>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                <CustomTable
                  data={notifications}
                  columns={columns}
                  loading={loading}
                />
                <CustomPagination
                  total={totalNotifications}
                  handleLoadData={(page, count) => fetchData(page, count)}
                />
              </>
            )}
          </Wrapper>
        )}

        {/* Tab 2: Template */}
        {tabValue === 2 && (
          <Wrapper>
            <div className="template-table-container">
              <Button
                className="add-template-btn"
                type="primary"
                onClick={handleAddTemplate}
              >
                Add Template
              </Button>
            </div>
            <CustomTable
              data={templates}
              columns={templateColumns}
              loading={loading}
            />
            <CustomPagination
              total={totalTemplates}
              handleLoadData={(page, count) => {
                dispatch(fetchNotificationTemplates(setLoading, page, count));
              }}
            />
          </Wrapper>
        )}

        <Modal
          visible={isTemplateModalVisible}
          onCancel={() => setTemplateModalVisible(false)}
          title={editingTemplate ? "Edit Template" : "Add Template"}
          footer={null}
        >
          <Form
            form={form}
            onFinish={handleSaveTemplate}
            initialValues={editingTemplate || { title: "", body: "" }}
          >
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: "Please enter the title" }]}
            >
              <AntdInput />
            </Form.Item>

            <Form.Item
              name="body"
              label="Description"
              rules={[
                { required: true, message: "Please enter the description" },
              ]}
            >
              <AntdInput.TextArea />
            </Form.Item>

            <Button type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </Form>
        </Modal>
      </Box>
    </Box>
  );
};

export default PushNotifications;
