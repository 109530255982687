import React from "react";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  FaChevronDown,
  FaBroadcastTower,
  FaTags,
  FaBullhorn,
  FaBell,
} from "react-icons/fa"; // Imported icons
import { ROUTES } from "../../constant";
import { logout } from "../../redux/action/authActions";
import { useDispatch } from "react-redux";

const iconSize = 20; // Set a consistent size for all icons

const Sidebar = ({ visible, setVisible, userType }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const handleShowLinks = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <Container>
      <NavLink to={ROUTES.ROOT}>
        <svg
          width={iconSize}
          height={iconSize}
          viewBox="0 0 19 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 10.9462H8.44444V0.390625H0V10.9462ZM0 19.3906H8.44444V13.0573H0V19.3906ZM10.5556 19.3906H19V8.83507H10.5556V19.3906ZM10.5556 0.390625V6.72396H19V0.390625H10.5556Z"
            fill="currentColor"
          />
        </svg>
        <span>Dashboard</span>
      </NavLink>

      <NavLink to={ROUTES.ADMIN}>
        <svg
          width={iconSize + 8} // Slightly bigger for distinction
          height={iconSize + 9}
          viewBox="0 0 28 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.3333 14.5319C13.05 14.5319 11.9514 14.0758 11.0375 13.1635C10.1236 12.2512 9.66667 11.1545 9.66667 9.87338C9.66667 8.59228 10.1236 7.49559 11.0375 6.58329C11.9514 5.67099 13.05 5.21484 14.3333 5.21484C15.6167 5.21484 16.7153 5.67099 17.6292 6.58329C18.5431 7.49559 19 8.59228 19 9.87338C19 11.1545 18.5431 12.2512 17.6292 13.1635C16.7153 14.0758 15.6167 14.5319 14.3333 14.5319ZM5 23.849V20.588C5 19.9281 5.17014 19.3215 5.51042 18.7683C5.85069 18.2151 6.30278 17.7929 6.86667 17.5017C8.07222 16.9 9.29722 16.4487 10.5417 16.1478C11.7861 15.847 13.05 15.6966 14.3333 15.6966C15.6167 15.6966 16.8806 15.847 18.125 16.1478C19.3694 16.4487 20.5944 16.9 21.8 17.5017C22.3639 17.7929 22.816 18.2151 23.1562 18.7683C23.4965 19.3215 23.6667 19.9281 23.6667 20.588V23.849H5Z"
            fill="currentColor"
          />
          <path
            d="M26.16 7.06284H22.928V10.3748H21.232V7.06284H18V5.52684H21.232V2.21484H22.928V5.52684H26.16V7.06284Z"
            fill="currentColor"
          />
        </svg>
        <span>Admin</span>
      </NavLink>

      <NavLink to={ROUTES.USER}>
        <svg
          width={iconSize + 3}
          height={iconSize + 1}
          viewBox="0 0 23 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 11.6797C13.1055 11.6797 15.625 9.16016 15.625 6.05469C15.625 2.94922 13.1055 0.429688 10 0.429688C6.89453 0.429688 4.375 2.94922 4.375 6.05469C4.375 9.16016 6.89453 11.6797 10 11.6797ZM15 12.9297H12.8477C11.9805 13.3281 11.0156 13.5547 10 13.5547C8.98438 13.5547 8.02344 13.3281 7.15234 12.9297H5C2.23828 12.9297 0 15.168 0 17.9297V18.5547C0 19.5898 0.839844 20.4297 1.875 20.4297H18.125C19.1602 20.4297 20 19.5898 20 18.5547V17.9297C20 15.168 17.7617 12.9297 15 12.9297Z"
            fill="currentColor"
          />
        </svg>
        <span>User Management</span>
      </NavLink>

      <NavLink to={ROUTES.CUSTOMCHANNELS}>
        <FaBroadcastTower size={iconSize} /> {/* Custom Channels Icon */}
        <span>Custom Channels</span>
      </NavLink>

      <NavLink to={ROUTES.CATEGORIES}>
        <FaTags size={iconSize} /> {/* Categories Icon */}
        <span>Categories</span>
      </NavLink>

      <NavLink to={ROUTES.PROMOTIONS}>
        <FaBullhorn size={iconSize} /> {/* Promotions Icon */}
        <span>Promotions</span>
      </NavLink>

      <NavLink to={ROUTES.PUSH_NOTIFICATIONS}>
        <FaBell size={iconSize} /> {/* Push Notifications Icon */}
        <span>Push Notifications</span>
      </NavLink>

      <LoginArea>
        <NavLink
          to="/"
          onClick={(e) => {
            e.preventDefault();
            dispatch(logout());
          }}
        >
          <svg
            width={iconSize + 1}
            height={iconSize}
            viewBox="0 0 21 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.85 8.62777L16 8.62777L16 10.6243L3.85 10.6243L5.4 12.1716L4 13.6191L7.86805e-07 9.62603L4 5.633L5.4 7.08047L3.85 8.62777ZM9 12.6208L9 16.6138L19 16.6138L19 2.63823L9 2.63822L9 6.63126L7 6.63126L7 2.63822C7 2.08918 7.19583 1.61917 7.5875 1.22818C7.97917 0.837201 8.45 0.641709 9 0.641709L19 0.64171C19.55 0.64171 20.0208 0.837202 20.4125 1.22819C20.8042 1.61917 21 2.08918 21 2.63823L21 16.6138C21 17.1629 20.8042 17.6329 20.4125 18.0239C20.0208 18.4149 19.55 18.6104 19 18.6104L9 18.6104C8.45 18.6104 7.97917 18.4149 7.5875 18.0239C7.19583 17.6329 7 17.1629 7 16.6138L7 12.6208L9 12.6208Z"
              fill="currentColor"
            />
          </svg>
          <span>Log Out</span>
        </NavLink>
      </LoginArea>
    </Container>
  );
};

export default Sidebar;

const Container = styled("div")`
  display: flex;
  align-items: center;
  padding: 2em 1.8em;
  flex-direction: column;
  background: #15112c;
  width: 328px;
  height: 100vh;
  .active,
  a {
    display: flex;
    align-items: center;
    margin-bottom: 0.2em;
    background: #15112c;
    color: #8c8c8c;
    width: 100%;
    height: 52px;
    padding: 0 0 0 24px;
    border-radius: 12px;
    &:hover {
      background: #3a49d6;
      color: #ffffff;
    }
    svg {
      margin: 1em;
    }
  }
  .active {
    background: #3a49d6;
    color: #ffffff;
  }
  @media (max-width: 993px) {
    & {
      position: absolute;
      z-index: 111;
      border-right: 1px solid #ffffff;
    }
  }
  @media (max-width: 560px) {
    & {
      width: 57%;
      position: absolute;
      z-index: 111;
      border-right: 1px solid #ffffff;
      height: 85%;
    }
  }
`;

const LoginArea = styled("div")`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15em;
`;

const DropdownLinks = styled("div")`
  width: 100%;
`;
const DropdownBtn = styled("div")`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: 1em;
  background: #15112c;
  color: #8c8c8c;
  width: 100%;
  padding: 0 0 0 24px;
  border-radius: 12px;
  svg {
    margin: 1em;
  }
  a {
    width: 100%;
  }
  .linkText {
    width: 100%;
  }
`;
