import React, { useEffect, useState } from "react";
import {
  Container,
  CustomTabs,
  FormContainer,
  Heading,
  Images,
  LoginForm,
  Overlay,
} from "./index.style";
import { Button, Checkbox, Form } from "antd";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import CustomInput from "../../components/CustomInput/CustomInput";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constant";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/action/authActions";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (values) => {
    setIsLoading(true);
    dispatch(login(values, setIsLoading));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // navigate(ROUTES.ADMIN);
  };

  useEffect(() => {
    if (user) {
      navigate(ROUTES.ROOT);
    } else {
      navigate(ROUTES.LOGIN);
    }
  }, [user]);

  return (
    <Container>
      <Images>
        {/* <Overlay></Overlay> */}
        {/* <img src="/images/auth.jpg" alt="" /> */}
        <img src="/images/logo.png" alt="" />
        {/* <Heading>
          <h2>Your World, Audibly Different</h2>
          <p>Caring for You, Every Step</p>
        </Heading> */}
      </Images>
      <FormContainer>
        <p>Welcome to AudioOne Admin Dashboard</p>
        <h3>Login</h3>
        {/* <CustomTabs
          centered
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        /> */}
        <LoginForm
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <CustomInput label="Email Address" name="email" />
          <PasswordInput
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: `Please Password!`,
              },
            ]}
          />
          <Form.Item
            name="remember"
            className="rememberInput"
            valuePropName="checked"
          >
            <Checkbox>Remember me</Checkbox>
            <Link to="/">Forgot Password ?</Link>
          </Form.Item>

          <Form.Item className="buttonArea">
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </LoginForm>
      </FormContainer>
    </Container>
  );
};

export default Login;
