import { Modal } from "antd";
import styled from "styled-components";

export const CreateModal = styled(Modal)`
  .ant-modal-header {
    background: #15112c;
    text-align: left;
    h3 {
      color: #ffffff;
      font-weight: 500;
      font-size: 1.2em;
      margin-top: 0em;
    }

    .modalTitle {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      background: #24204a;
      padding: 0.5em;
      border-bottom: 1px solid;
    }
  }
  .rememberInput {
    margin-top: 1em;
    position: relative;
    .ant-checkbox-wrapper {
      span {
        color: #ffffff !important;
      }
    }
    a {
      position: absolute;
      right: 0;
      color: #ffffff;
    }
    .ant-checkbox-inner {
      background: #15112c !important;
    }
  }
  .ant-modal-content {
    border: 1px solid #ffffff;
    padding: 3em 2em;
    background: #15112c;
    overflow: hidden;
    h3 {
      color: #ffffff;
      font-size: 1.4em;
      margin-bottom: 0.5em;
    }
    p {
      color: #ffffff;
    }
    .ant-modal-close {
      background: #ffffff;
      border-radius: 50%;
    }
    .ant-select {
      width: 100%;
      color: #ffffff;
      background: #15112c;
      width: 100%;
      height: 54px;
      .ant-select-selection-placeholder {
        color: #ffffff;
      }
    }
    .ant-select-selector {
      color: #ffffff;
      background: #15112c !important;
      border-radius: 40px;
    }
    .ant-select-arrow {
      transform: rotateX(45deg);
      svg {
        color: #ffffff;
        font-size: 1.1em;
      }
    }
    .ant-picker {
      border: 1px solid #ffffff;
      color: #ffffff;
      background: #15112c;
      width: 100%;
      height: 46px;
      border-radius: 40px;
      margin-right: 1em;
      input {
        ::placeholder {
          color: #ffffff;
        }
      }
      .ant-picker-input {
        flex-direction: row-reverse;
      }
      .ant-picker-clear,
      .ant-picker-suffix {
        display: none;
        margin-right: 0.5em;
        svg {
          color: #ffffff;
        }
        &:hover {
          svg {
            color: #ffffff;
          }
        }
      }
      .ant-picker-clear {
        display: none;
      }
    }
    textarea {
      background: #15112c !important;
      ::placeholder {
        color: #ffffff99;
      }
    }
    label {
      color: #ffffff;
    }
  }
  .ant-upload-select {
    color: #ffffff;
    border: 1px solid;
    display: flex;
    width: 100%;
    padding: 1em;
    border-radius: 40px;
    .ant-upload {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 0.5em;
    }
  }
  @media (max-width: 768px) {
    & {
      .ant-modal-header {
        h3 {
          color: #ffffff;
          font-weight: 400;
          font-size: 1em;
        }
      }
      .ant-modal-content {
        border: 1px solid #ffffff;
        padding: 2em 1em 1em;
        .ant-modal-body {
          .ant-checkbox-wrapper {
            display: none !important;
          }
        }
        .ant-btn {
          width: 150px;
        }
        .ant-modal-close {
          width: 20px;
          height: 20px;
          svg {
            font-size: 1em !important;
          }
        }
      }
    }
  }
`;

export const FormFooter = styled("div")`
  padding: 2em;
  text-align: right;
  border-top: 1px solid #ffffff90;
  .ant-btn {
    padding: 0em 2em;
    height: 40px;
    border-radius: 33px;
    border: 1px solid #3a49d6;
    background: #3a49d6;
    span {
      color: #ffffff;
      font-size: 1.1em;
    }
    &:hover {
      border: 1px solid #3a49d6 !important;
      background: #3a49d6 !important;
      span {
        color: #ffffff;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      border-top: none;
      padding: 0.5em 0em;
      .ant-btn {
        width: 100%;
      }
    }
  }
`;
