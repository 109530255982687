import React from "react";
import { StyledTable } from "./index.styled";

const CustomTable = ({ data, columns, onRow, loading, disablePagination}) => {
  return (
    <div className="todoDataTable">
      <StyledTable
        onRow={onRow}
        dataSource={data}
        columns={columns}
        loading={loading}
        pagination={!disablePagination}
      />
    </div>
  );
};

export default CustomTable;
