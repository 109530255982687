import styled from "styled-components";

export const Wrapper = styled("div")`
  padding: 3em 0em;
`;
export const ImageSection = styled("div")`
  padding: 0.5em 0;
  border-radius: 1em;
  //   height: 258px;
  margin-bottom: 1.3em;
  background: #15112c;
  h1 {
    border-bottom: 1px solid #ffffff99;
    margin: 0.2em 0;
    padding-left: 1em;
    padding-bottom: 0.3em;
    text-transform: uppercase;
    font-size: 1.3em;
    font-weight: 600;
  }
  form {
    padding: 2em 2em 0;
  }
  label {
    color: #ffffff !important;
  }
  .ant-select {
    width: 100%;
    color: #ffffff !important;
    background: #15112c;
    width: 100%;
    height: 54px;
    .ant-select-selection-placeholder {
      color: #ffffff;
    }
  }
  .ant-select-selector {
    color: #ffffff !important;
    background: #15112c !important;
    border-radius: 40px;
  }
  .ant-select-arrow {
    transform: rotateX(45deg);
    svg {
      color: #ffffff;
      font-size: 1.1em;
    }
  }
  @media (max-width: 992px) {
    & {
      h1 {
        font-size: 1em;
      }
      form {
        padding: 2em 1em 0;
      }
    }
  }
`;

export const UploadArea = styled("div")`
  text-align: center;
  span {
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 0.3em;
    display: inline-block;
    color: #ffffff;
  }
`;
export const Avatar = styled("div")`
  width: 82px;
  height: 82px;
  overflow: hidden;
  border-radius: 50%;
  margin: auto;
  img {
    width: 100%;
  }
`;

export const FormFooter = styled("div")`
  padding: 2em;
  text-align: right;
  border-top: 1px solid #ffffff90;
  .ant-btn {
    padding: 0em 2em;
    height: 40px;
    border-radius: 33px;
    border: 1px solid #3a49d6;
    background: #3a49d6;
    span {
      color: #ffffff;
      font-size: 1.1em;
    }
    &:hover {
      border: 1px solid #3a49d6 !important;
      background: #3a49d6 !important;
      span {
        color: #ffffff;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      border-top: none;
      padding: 0.5em 0em;
      .ant-btn {
        width: 100%;
      }
    }
  }
`;
