import styled from "styled-components";

export const Wrapper = styled("div")`
  padding-bottom: 6em;
  margin-top: 3em;

  .hideData {
    display: none;
    @media (max-width: 768px) {
      & {
        display: block;
      }
    }
  }

  .showData {
    display: block;
    @media (max-width: 768px) {
      & {
        display: none;
      }
    }
  }

  .mobileTable {
    overflow-x: auto;
    padding-bottom: 1em;
    &::-webkit-scrollbar-thumb {
      background: #888 !important;
    }
    &::-webkit-scrollbar {
      height: 5px !important;
    }
  }

  .tabContainer {
    border-radius: 12px;
    background: #15112c;
    padding: 0em 1em;

    .ant-collapse {
      .ant-table-thead,
      .ant-table-tbody {
        .ant-table-cell {
          background: #00000000 !important;
          padding-left: 0em !important;
        }
      }
      .patients {
        padding-left: 0em !important;
      }
    }

    .ant-collapse-header {
      padding: 1em 0 !important;

      .ant-collapse-expand-icon {
        right: -15px !important;
      }
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 2em !important;
  }
`;

export const CollapseData = styled("div")`
  width: 100%;

  .ant-collapse {
    border: none;
    background: transparent !important;
    width: 100%;
  }

  .ant-collapse-item {
    border: none;
    border-radius: 12px 12px 0 0 !important;
    background: #15112c;
    box-shadow: 0px 1px 3px 0px #00000014;
    margin-bottom: 1em;
    overflow: hidden;
    border-bottom: 1px solid #ffffff90;

    .ant-collapse-header {
      background: #15112c;
      color: #ffffff;

      .ant-collapse-expand-icon {
        position: absolute;
        right: 0;
        top: 23px;

        svg {
          font-size: 1.5em;
          transform: rotate(90deg);
        }
      }
    }

    .ant-collapse-content,
    .ant-collapse-content-active {
      background: #15112c;
      color: #ffffff;
    }
  }

  .ant-collapse-item-active {
    border-radius: 12px !important;
    border-bottom: none !important;

    .ant-collapse-header {
      .ant-collapse-expand-icon {
        svg {
          transform: rotate(-90deg) !important;
        }
      }
    }
  }

  .ant-collapse-content {
    border-top: 1px solid #ffffff90;
  }

  .ant-collapse-content-box {
    padding: 0.5em 0 2em !important;
  }
`;

export const CollapseHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  margin-right: 1.5em;

  h1 {
    margin: 0;
    font-size: 1.3em;
    font-weight: 500;
    text-transform: uppercase;

    span {
      font-size: 0.8em;
      font-weight: 400;
    }
  }

  span {
    font-size: 0.9em;
  }
`;

export const HeaderContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
`;

// export const SearchArea = styled("div")`
//   flex: 1;
//   margin-bottom: 2.5em; /* Add margin to separate the search area from the button */

//   .ant-input-search {
//     background: #15112c;
//   }

//   .ant-input-wrapper {
//     border-radius: 40px;
//     background: #15112c;
//     color: #ffffff;
//     border: 1px solid #ffffff;
//     display: flex;
//     align-items: center;
//     justify-content: start;
//     flex-direction: row-reverse !important;
//     overflow: hidden;
//     height: 50px;
//     padding-left: 0.5em;

//     input {
//       border: none;
//       font-size: 1em;
//       background: #15112c;
//       color: #ffffff;

//       :focus {
//         border-color: #15112c;
//         box-shadow: none;
//       }

//       &::placeholder {
//         color: #ffffff90;
//       }
//     }

//     button {
//       border: none;
//       width: 40px;
//       height: 40px;
//       background: url(/icons/search-icon.svg);
//       background-repeat: no-repeat;
//       background-size: 52%;
//       background-position: center;

//       span {
//         opacity: 0;
//       }

//       &:hover,
//       &:focus {
//         background: url(/icons/search-icon.svg) !important;
//         width: 40px !important;
//         height: 40px !important;
//         background-position: center !important;
//         background-repeat: no-repeat !important;
//         background-size: 52% !important;
//         border-color: #15112c;
//         box-shadow: none;
//       }
//     }
//   }

//   .ant-input-group-addon {
//     background: #15112c;
//     overflow: hidden;
//     width: 50px;

//     :last-child {
//       left: 0px !important;
//     }
//   }
// `;

export const ButtonContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin-bottom: auto; /* Space between the button and the table */
`;
