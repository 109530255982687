import { combineReducers } from "redux";
import authReducers from "./authReducers";
import appReducers from "./appReducers";

const rootReducer = combineReducers({
  auth: authReducers,
  app: appReducers,
});

export default rootReducer;
