import React, { useEffect, useState } from "react";
import { Collapse, Input } from "antd";
import CustomTable from "../../components/customTable";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../redux/action/appActions";
import {
  CollapseData,
  CollapseHeader,
  SearchArea,
  Wrapper,
} from "./index.styled";

const { Search } = Input;

const CategoriesPage = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.app.categories);
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [data, setData] = useState([]);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text) => text !== "" && text !== "undefined" && text,
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      render: (text) => text !== "" && text !== "undefined" && text,
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive) => (isActive ? "Yes" : "No"),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => {
        const formattedDate = new Date(date).toLocaleDateString();
        const formattedTime = new Date(date).toLocaleTimeString();
        return `${formattedDate} ${formattedTime}`;
      },
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => {
        const formattedDate = new Date(date).toLocaleDateString();
        const formattedTime = new Date(date).toLocaleTimeString();
        return `${formattedDate} ${formattedTime}`;
      },
    },
  ];

  const items = [
    {
      key: "1",
      label: (
        <CollapseHeader>
          <h1>
            Categories <span>{`(${categories?.length || 0})`}</span>
          </h1>
          <span>view all</span>
        </CollapseHeader>
      ),
      children: (
        <CustomTable
          data={data}
          columns={columns}
          loading={loading}
          disablePagination={true}
        />
      ),
    },
  ];

  const fetchData = (page, count, query) => {
    setLoading(true);
    dispatch(getCategories(setLoading, page, count, query));
  };

  const onSearch = (value) => {
    setSearchKeyword(value);
  };

  useEffect(() => {
    if (user) {
      fetchData(1, 10, searchKeyword);
    }
  }, [searchKeyword, user]);

  useEffect(() => {
    if (categories) {
      setData(categories);
    }
  }, [categories]);

  return (
    <>
      <SearchArea>
        <Search placeholder="Search Categories" onSearch={onSearch} />
      </SearchArea>
      <Wrapper>
        <div className="tabContainer">
          <CollapseData>
            <Collapse items={items} defaultActiveKey={1} />
          </CollapseData>
        </div>
      </Wrapper>
    </>
  );
};

export default CategoriesPage;
