import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGOUT,
} from "../Constants";
import setAuthToken from "../../helpers/setAuthToken";

const initialState = {
  user: null,
  token: localStorage.getItem("token"), // Retrieve token from localStorage initially
  isAuthenticated: null, // Check if the user is authenticated
  loading: true, // Set to true until user information is loaded
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true, // Set authenticated state to true when user is loaded
        loading: false, // Loading complete
        user: payload, // Store user data
      };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      localStorage.setItem("token", payload.token); // Save token in localStorage
      setAuthToken(payload.token); // Set token in headers
      return {
        ...state,
        token: payload.token, // Set token
        isAuthenticated: true, // User is authenticated
        loading: false, // Loading complete
        user: payload.user, // Store user data
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case REGISTER_FAILURE:
    case LOGOUT:
      localStorage.removeItem("token"); // Clear token on error or logout
      return {
        ...state,
        token: null, // Remove token
        isAuthenticated: false, // User is no longer authenticated
        loading: false, // Loading complete
        user: null, // Clear user data
      };
    default:
      return state;
  }
};

export default authReducer;
