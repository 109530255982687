import { Modal } from "antd";
import styled from "styled-components";

export const MessageContainer = styled(Modal)`
  .ant-modal-header {
    background: #15112c;
    text-align: left;
    h3 {
      color: #ffffff;
      font-weight: 500;
      font-size: 1.2em;
      margin-top: 0em;
    }
  }
  .line {
    height: 2px;
    background: #ffffff20;
  }
  .ant-modal-content {
    border: 1px solid #ffffff;
    text-align: center;
    background: #15112c;
    h3 {
      color: #ffffff;
      font-size: 1.4em;
      margin-bottom: 0.5em;
    }
    h6,
    span,
    p {
      color: #ffffff;
    }
    .ant-modal-close {
      background: #ffffff;
      border-radius: 50%;
      svg {
        color: #000000;
      }
    }
  }
  @media (max-width: 786px) {
    & {
      .ant-modal-content {
        h3 {
          color: #ffffff;
          font-size: 1em;
          margin-bottom: 0.5em;
        }
        h6,
        span,
        p {
          font-size: 0.8em;
          color: #ffffff;
        }
        .ant-modal-close {
          background: #ffffff;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          svg {
            font-size: 1.5em;
            color: #000000;
          }
        }
      }
    }
  }
`;

export const Buttons = styled("div")`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: auto;
  .ant-btn {
    width: 180px;
    height: 44px;
    border-radius: 33px;
    background: #15112c;
    span {
      color: #ffffff;
    }
    &:last-child {
      background: #ff8888 !important;
      border: 1px solid #ff8888;
      span {
        color: #000000;
      }
    }
  }
  @media (max-width: 786px) {
    & {
      flex-direction: column;
      .ant-btn {
        width: 100%;
      }
    }
  }
`;
